import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Box, Button, Paper, Typography } from "@mui/material";
import { verify } from "../services/Tables";
import { getByID } from "../services/Partners";
import { getLatest } from "../services/Products";
import { getByPartnerID } from "../services/Review";
import ls from "local-storage";
import { Grid } from "@mui/material";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import * as geolib from "geolib";
import DefaultContainer from "../components/DefaultContainer";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import qs from "query-string";
import Toast from "../components/Snackbar";
import SkeletonOnboard from "../components/SkeletonOnboard";
import StarRateIcon from "@mui/icons-material/StarRate";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import StoreOutlinedIcon from "@mui/icons-material/StoreOutlined";
import LatestMenuCard from "../components/LatestMenuCard";
import ReviewCard from "./ReviewCard";
import LocationAccess, {
  access,
  toAccessDenied,
} from "../components/LocationAccess";
import { userLogout } from "../config/redux/actions/userActions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getStatusForQR } from "../services/Transactions";

const OnboardingScreen = ({ userLogout }) => {
  const navigate = useNavigate();
  const [partnerID, setPartnerID] = React.useState("000035");
  const [tableID, setTableID] = React.useState("M1");
  const [partnerDetails, setPartnerDetails] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [openingHour, setOpeningHour] = useState([]);
  const [distance, setDistance] = useState(0);
  const [isPartnerDetail, setIsPartnerDetail] = useState(false);
  const [userLatLong, setUserLatLong] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [closeOrder, setCloseOrder] = useState(0);
  const [closeHour, setCloseHour] = useState("");
  const [lastOrder, setLastOrder] = useState("");
  const [isScheduleExpand, setIsScheduleExpand] = useState(false);
  const [isAccesLoc, setIsAccesLoc] = useState(true);
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [status, setStatus] = useState(0);
  const [msg, setMsg] = useState("");
  const [latestMenu, setLatestMenu] = useState([]);
  const [lastReview, setLastReview] = useState("");
  const [averageReview, setAverageReview] = useState(0);
  const [reviewCount, setReviewCount] = useState(0);
  const [hitCounter, setHitCounter] = useState(0)
  const [statusValidation, setStatusValidation] = useState(true)
  const [isOnboard, setIsOnboard] = useState("close");
  const [isDenied, setIsDenied] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [aos, setAos] = useState(0);

  const disabledButton = {
    disabled: true,
    bgcolor: "gray",
    cursor: "default",
  };

  const enabledButton = {
    disabled: false,
    bgcolor: "#1fb0e6",
    cursor: "pointer",
  };

  const [buttonBehavior, setButtonBehavior] = useState(disabledButton);

  const hours = new Date().getHours();
  const minutes = new Date().getMinutes();
  const seconds = new Date().getSeconds();
  const days = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];
  const location = useLocation();
  const timeNow = `${hours > 9 ? hours : "0" + hours}:${
    minutes > 9 ? minutes : "0" + minutes
  }:${seconds > 9 ? seconds : "0" + seconds}`;

  const thisDay = days[new Date().getDay()];

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  
  async function checkStatus(id, phone){

    const query = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    const forceQR = query && query.forceQR ? query.forceQR : 0

    const response = await getStatusForQR(id, phone, forceQR)

    // console.log(response)
    const trxStatus = response.transactionStatus === "5" ? true : false

    const validation = response.msg === "Terdapat Menu Konsinyasi Dalam Transaksi" ? true : false
    
    if(validation){
      setMsg(response.msg);
      setStatus(2);
      ls.set("forceQR", "1")
      setStatusValidation(false)
    } else {
      setStatusValidation(trxStatus)
    }
    
  }

  async function fetchPartnerDetail(id) {
    const query = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      }
    );

    await getByID(id).then((response) => {
      setPartnerDetails(response.partners[0]);
      if (response.partners.length > 0) {
        setIsPartnerDetail(true);
        ls.set("sfPartner", response.partners[0]);  
        setAos(ls.get("sfPartner").allow_override_stock);
        let obj = {...query}

        if(!query.id && ls.get("trxFromQR")){
          obj.id = ls.get("trxFromQR")
        }

        if(ls.get("trxFromQR") && !query.tempQR){
          obj.tempQR = '1'
        }

        if(ls.get("forceQR") && !query.forceQR){
          obj.forceQR = '1'
        }

        console.log(obj)

        setSearchParams(obj)

        if(query.id && response.partners[0].is_temporary_qr === "1"){
          const trxID = query.id.includes("%2F") ? decodeURI(query.id) : query.id

          ls.set("trxFromQR", trxID);
          ls.set("partnerQR", query.partnerID);
        } else if(ls.get("trxFromQR") && (response.partners[0].id !== ls.get("partnerQR") || parseInt(response.partners[0].is_temporary_qr) !== 1 ) ){
          console.log("hit this")
          ls.remove("trxFromQR")
          ls.remove("partnerQR")
          ls.remove("forceQR")
        }

        if((query.id || ls.get("trxFromQR")) && response.partners[0].is_temporary_qr === "1" ){
          checkStatus(query.id ?? ls.get("trxFromQR"), ls.get("redux_localstorage_simple")?.userData[ls.get("redux_localstorage_simple")?.userData.length - 1])
        } else if((!query.id && !ls.get("trxFromQR")) && response.partners[0]?.is_temporary_qr === "1"){
          setStatusValidation(false)
          setMsg("Mohon Scan QR Dari Waiter")
          setStatus(0)
        }
      }
      setOpeningHour(response.openingHour[0]);
      setLoading(false);
    });
  }

  async function validateParams(partnerID, tableID) {
    setLoading(true);
    
    const query = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    
    const lastOrderMsg = "Sudah melewati batas last order. Coba lagi besok";
    await verify(partnerID, tableID).then((response) => {
      fetchPartnerDetail(partnerID);
      if (
        response.success === 1 ||
        response.shift === "0" ||
        response.shift === "1"
      ) {
      
        if(tableID !== "null" || !tableID){
          ls.set("sfShift", response.shift);
        } else {
          ls.set("sfShift", "");
        }
      } else if (response.msg === lastOrderMsg) {
        setCloseOrder(1);
      }
    });
  }

  async function handleStatusValidation(){
    setIsToastOpen(true);
    if(msg !== "Mohon Scan QR Dari Waiter" && msg !== "Terdapat Menu Konsinyasi Dalam Transaksi"){
      setMsg("Transaksi Sudah Dibayar, Panggil Waiter Untuk Mendapatkan QR Baru");
    } 
    setStatus(0); 
  }

  function getUserLatLong() {
    function getLocation() {
      if (!navigator.geolocation) {
        setIsAccesLoc(false);
      } else {
        navigator.geolocation.getCurrentPosition(success, error);
      }
    }

    function success(position) {
      setIsAccesLoc(true);
      setUserLatLong({
        lat: position.coords.latitude,
        long: position.coords.longitude,
      });
    }

    function error() {
      setIsAccesLoc(false);
    }

    getLocation();
  }

  function getShopDistance() {
    const distanceFuncScope = geolib.getPreciseDistance(
      {
        latitude: partnerDetails.latitude ?? 0,
        longitude: partnerDetails.longitude ?? 0,
      },
      { latitude: userLatLong.lat ?? 0, longitude: userLatLong.long ?? 0 }
    );

    setDistance(distanceFuncScope);

    if (parseInt(distanceFuncScope) > 999999999) {
      setButtonBehavior(disabledButton);
      ls.set("sfButtonBehavior", disabledButton);
    } else {
      setButtonBehavior(enabledButton);
      ls.set("sfButtonBehavior", enabledButton);
    }
  }

  function isOpenHour() {
    const timeTodayOpen = openingHour[`${thisDay}_open`];
    ls.set("sfOpenHour", timeTodayOpen);
    const timeTodayClose = openingHour[`${thisDay}_closed`];
    ls.set("sfCloseHour", timeTodayClose);
    const timeTodayLastOrder = openingHour[`${thisDay}_last_order`];
    ls.set("sfLastOrder", timeTodayLastOrder);
    const thisTime = timeNow;
    const timeClose =
      timeTodayClose.split(":")[0] + ":" + timeTodayClose.split(":")[1];
    setCloseHour(timeClose);
    setLastOrder(
      timeTodayLastOrder.split(":")[0] + ":" + timeTodayLastOrder.split(":")[1]
    );
    const temporaryClose = ls.get('sfPartner').is_temporary_close;
    if (timeTodayOpen > thisTime) {
      setButtonBehavior(disabledButton)
      setIsOpen(false);
    } else if (timeTodayClose > thisTime) {
      setButtonBehavior(enabledButton)
      setIsOpen(true);
    } else if (thisTime > timeTodayClose) {
      setButtonBehavior(disabledButton)
      setIsOpen(false);
    }
     else {
      setIsOpen(true);
    } if (temporaryClose === "1" ){
      // setButtonBehavior(disabledButton)
      setIsOpen(false);
    }
  }

  async function fetchLatestMenu() {
    const response = await getLatest(ls.get("partnerID"));
    console.log(String(ls.get("partnerID")))
    
    if (response.success === 1) {
      if(aos === "0"){
        const filteredMenu = response.menus.filter((item)=>{
          return item.stock !== "0"
        })
        setLatestMenu(filteredMenu)
      }else{
        setLatestMenu(response.menus);
      }
    }
  }

  async function fetchReview(partnerID) {
    const response = await getByPartnerID(partnerID);

    if (response.success === 1) {
      const data = response.reviews;

      if (data.length > 0) {
        const lastData = data.filter((data) => data.review !== " ")[0];
        setLastReview(lastData);
      }

      setReviewCount(parseInt(response.count));
      setAverageReview(parseFloat(response.average.toFixed(1)));
    }
  }

  const [width, setWindowWidth] = useState(0);
  // const [height, setWindowHeight] = useState(0);

  const updateDimensions = () => {
    const width = window.innerWidth;      
    // const height = window.innerHeight;
    setWindowWidth(width);
    // setWindowHeight(height);
  };

  function removeLocalStorage(){
    ls.remove("sfCloseHour")
    ls.remove("sfPartner")
    ls.remove("sfButtonBehavior")
    ls.remove("sfOpenHour")
    ls.remove("sfLastOrder")
    ls.remove("sfToken")
    ls.remove("tableID")
    ls.remove("partnerID")
    ls.remove("redux_localstorage_simple")
    userLogout()
    setHitCounter(hitCounter + 1)
    ls.set("functionHit", "1")
    ls.set( "sfToken", "V2tDRTRkTXRJajhMTllJajdOY3pucWlKQXBLV1l2RGR1UWtwL3IyZVd5d2FQSXR6a0NCZTl3dE1FN1p0ZDlZK2tuSlJ1Y0RsWHI1Q3VsNHFCdWZqTFZzVzN3ZWZHR2lNUUt3K05ka1hSQjU1a1FVTDArMW14MWFkS2pweDRJa2ExR001SzFZcFJoTjB6T1ZSR3M3ZEtBPT0=")
  }

  useEffect(() => {
    const query = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    if(hitCounter === 0 && (!ls.get("functionHit") || ls.get("functionHit") !== "1" || query.forceQR && query.forceQR == "true")){
      removeLocalStorage()
    }

    if(!ls.get("redux_localstorage_simple")){
      userLogout()
    }

    updateDimensions();
    fetchLatestMenu();

    if(query.tableID !== "null"){
      ls.remove("sfIsReview")
    }

    setPartnerID(query.partnerID);
    setTableID(query.tableID);
    fetchReview(query.partnerID);
    ls.set("partnerID", query.partnerID);


    if (isPartnerDetail) {
      if (isAccesLoc) {
        getShopDistance();
      } else {
        setButtonBehavior(disabledButton);
        ls.set("sfButtonBehavior", disabledButton);
      }
      isOpenHour();
    } else {
      if (ls.get("sfToken") === null) {
        ls.set(
          "sfToken",
          "V2tDRTRkTXRJajhMTllJajdOY3pucWlKQXBLV1l2RGR1UWtwL3IyZVd5d2FQSXR6a0NCZTl3dE1FN1p0ZDlZK2tuSlJ1Y0RsWHI1Q3VsNHFCdWZqTFZzVzN3ZWZHR2lNUUt3K05ka1hSQjU1a1FVTDArMW14MWFkS2pweDRJa2ExR001SzFZcFJoTjB6T1ZSR3M3ZEtBPT0="
        );
        ls.remove("redux_localstorage_simple")
      }
      validateParams(query.partnerID, query.tableID);
      // getUserLatLong();
      if(query.tableID){
        ls.set("tableID", query.tableID);
      }
    }

    // navigator.permissions
    //   .query({ name: "geolocation" })
    //   .then((permissionStatus) => {
    //     if (permissionStatus.state === "granted") {
    //       setIsDenied(false);
    //     }
    //   });

    // if(isAccesLoc){
    //   setIsDenied(true)
    // }
    // else{
    //   setIsDenied(false)
    // }

    if(isToastOpen){
      setTimeout(()=>setIsToastOpen(false), 1500)
    }


    window.addEventListener("resize", updateDimensions);

    // setTimeout(() => {
    //   if (isToastOpen && msg === "Anda belum berada dekat lokasi") {
    //     setIsToastOpen(false);
    //   }
    // }, 100);

    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, [partnerID, tableID, isPartnerDetail, isAccesLoc, isToastOpen, hitCounter]);


  function expand() {
    setIsScheduleExpand(!isScheduleExpand);
  }

  const handleDineIn = () => {
    const query = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    if(query.tableID !== "null"){
      if (closeOrder === 1 || closeOrder === "1") {
        setIsToastOpen(true);
        setMsg("Waktu sudah melebihi batas close order");
        setStatus(0);
      } else {
        if (isAccesLoc) {
          navigate(`/order?partnerID=${ls.get('partnerID')}`, { state: { details: partnerDetails } });
        } else if (!isAccesLoc) {
          window.location.reload();
        }
      }
    } else {
      setMsg("Scan Meja Kembali Untuk Memesan")
      setIsToastOpen(true)
    }

  };

  const toReviewList = () => {
    // navigate("/review-list")
    navigate("/review-list");
  };

  return (
    <DefaultContainer
      isExpand={isScheduleExpand}
      bgColor={"primary.main"}
      loading={loading}
    >
      <Toast isOpen={isToastOpen} setState={setIsToastOpen} message={msg} status={status} />
      {loading && closeOrder === 0 ? (
        <Box
          className="test"
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <SkeletonOnboard />
        </Box>
      ) : isAccesLoc ? (
        <Box
          style={{
            // paddingTop: "25vh",
            height: "100%",
            background: "#fff",
          }}
        >
          <Grid
            style={{
              height: 250,
              width: "100%",
              position: "relative",
            }}
          >
            <img
              alt=""
              src={
                partnerDetails?.img_map
                  ? partnerDetails?.img_map
                  : "/assets/img/logo/image-not-found.png"
              }
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                objectPosition: "center",
              }}
            />
          </Grid>
          <Grid
            style={{
              paddingTop: 15,
              paddingLeft: 15,
              paddingRight: 15,
              paddingBottom: 5,
            }}
          >
            <Typography
              style={{
                fontWeight: 500,
                fontSize: 20,
                // color: "red",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {partnerDetails.name}
            </Typography>
          </Grid>
          <Grid
            style={{
              paddingLeft: 15,
              paddingRight: 15,
              display: "flex",
              gap: 5,
            }}
          >
            <StarRateIcon
              style={{ color: "#FFDF00", marginLeft: -2, fontSize: 20 }}
            />
            <Typography
              style={{
                marginTop: 2,
                fontSize: 14,
              }}
            >
              {averageReview} dari {reviewCount} penilaian
            </Typography>
          </Grid>
          <Grid
            style={{
              paddingTop: 30,
              paddingLeft: 15,
              paddingRight: 15,
            }}
          >
            <Typography
              style={{
                fontSize: 14,
                color: "#6c757d",
              }}
            >
              {partnerDetails.address}
            </Typography>
            <Typography
              style={{
                fontSize: 14,
                color: "#6c757d",
              }}
            >
              {/* {isAccesLoc ? (
                <span style={{ margin: 0 }}>
                  Hanya{" "}
                  {distance >= 1000
                    ? `${(distance / 1000).toFixed(1)}`.replaceAll(".", ",")
                    : distance}{" "}
                  {distance < 1000 ? "m" : "Km"} dari lokasi kamu
                </span>
              ) : (
                <span style={{ margin: 0 }}>
                  Akses lokasi anda belum diatur
                </span>
              )} */}
            </Typography>
            <Typography
              style={{
                fontWeight: "400",
                fontSize: 14,
                display: "flex",
                alignItems: "center",
                color: "#6c757d",
              }}
            >
              <span
                style={{
                  fontWeight: "600",
                  color: isOpen ? "#28a745" : "red",
                }}
              >
                {isOpen ? "Buka" : "Tutup"}
              </span>
              , Last Order {lastOrder}, Tutup {closeHour}{" "}
              <KeyboardArrowDownIcon
                fontSize="small"
                style={{
                  marginLeft: 5,
                  transition: "0.3s",
                  transform: isScheduleExpand ? "rotate(180deg)" : "",
                }}
                onClick={() => expand()}
              />
            </Typography>
            <div
              style={{
                transition: "0.5s",
                width: "100%",
                height: isScheduleExpand ? "142px" : "0",
                overflow: "hidden",
              }}
            >
              {Object.keys(openingHour).length > 0 &&
                days.map((item, i) => {
                  return (
                    <div
                      key={i}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: 3,
                      }}
                    >
                      <p style={{ margin: 0, fontSize: 13 }}>{item}</p>
                      <p style={{ margin: 0, fontSize: 13 }}>
                        {openingHour[`${item}_open`].split(":")[0] +
                          ":" +
                          openingHour[`${item}_open`].split(":")[1]}
                        {" - "}
                        {openingHour[`${item}_closed`].split(":")[0] +
                          ":" +
                          openingHour[`${item}_closed`].split(":")[1]}
                      </p>
                    </div>
                  );
                })}
            </div>
          </Grid>
          <Grid
            style={{
              paddingTop: 10,
              paddingLeft: 15,
              paddingRight: 15,
            }}
          >
            <Grid
              item
              xs={12}
              onClick={(e) => {
                if (!buttonBehavior.disabled) {
                  if(statusValidation){
                    handleDineIn();
                  } else {
                    handleStatusValidation();
                  }
                }
              }}
            >
              <Item
                style={{
                  cursor: buttonBehavior.cursor,
                  background: buttonBehavior.bgcolor,
                  color: "#fff",
                  fontWeight: 500,
                }}
              >
                Pesan Sekarang
              </Item>
            </Grid>
          </Grid>
          <Grid
            style={{
              margin: "15px 0px",
              paddingTop: 10,
              paddingBottom: 10,
              paddingLeft: 15,
              paddingRight: 15,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
              borderTop: "0.5px solid #6c757d",
              borderBottom: "0.5px solid #6c757d",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              onClick={() => {
                // 1. Menghilangkan karakter selain angka
                let formatted = partnerDetails.phone.replace(/\D/g, "");

                // 2. Menghilangkan angka 0 di depan (prefix)
                //    Kemudian diganti dengan 62
                if (formatted.startsWith("0")) {
                  formatted = "62" + formatted.substr(1);
                }
                window.open("http://wa.me/" + formatted, "_blank");
              }}
            >
              <WhatsAppIcon
                style={{
                  fontSize: 30,
                  paddingBottom: 10,
                  color: "#6c757d",
                }}
              />
              <Typography>WA</Typography>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              onClick={() => {
                const lat = ls.get("sfPartner").latitude;
                const lng = ls.get("sfPartner").longitude;

                if (lat && lng) {
                  window.open(
                    "https://maps.google.com?q=" + lat + "," + lng,
                    "_blank"
                  );
                }
              }}
            >
              <PlaceOutlinedIcon
                style={{
                  fontSize: 30,
                  paddingBottom: 10,
                  color: "#6c757d",
                }}
              />
              <Typography>Peta</Typography>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              onClick={() => {
                const url = ls.get("sfPartner").url;
                if (url) {
                  window.open(url, "_blank");
                }
              }}
            >
              <StoreOutlinedIcon
                style={{
                  fontSize: 30,
                  paddingBottom: 10,
                  color: "#6c757d",
                }}
              />
              <Typography>Profil</Typography>
            </div>
          </Grid>
          <Grid
            style={{
              paddingLeft: 15,
              paddingRight: 15,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <Typography style={{ fontWeight: 500 }}>Menu</Typography>
              <Typography style={{ fontWeight: 300 }}>Terbaru</Typography>
            </div>
            <Button
              // disabled={!isOpen}
              variant="outlined"
              onClick={() => {
                if(statusValidation){
                  handleDineIn();
                } else {
                  handleStatusValidation();
                }
              }}
              style={{
                height: 36.5,
              }}
            >
              Lihat Semua
            </Button>
          </Grid>
          <Grid
            style={{
              padding: 15,
              height: latestMenu.length > 0 ? 320 : 70,
            }}
          >
            <Grid
              className="latest_menu"
              style={{
                width: "100%",  
                height: latestMenu.length > 0 ? 310 : 70,
                overflowY: "hidden",
                position: "relative",
                display: latestMenu.length > 0 ? "" : "flex",
                justifyContent: latestMenu.length > 0 ? "" : "center",
              }}
            >
              <Grid
                style={{
                  position: "absolute",
                  display: "flex",
                  width: "fit-content",
                  gap: 15,
                }}
              >
                {latestMenu.length > 0 ? (
                  latestMenu.map((menu) => {
                    return (
                      <LatestMenuCard
                        menu={menu}
                        key={menu.id}
                        setIsToastOpen={setIsToastOpen}
                        setMsg={setMsg}
                        statusValidation={statusValidation}
                        handler={handleStatusValidation}
                      />
                    );
                  })
                ) : (
                  <>Belum ada menu</>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            style={{
              marginTop: 30,
              paddingLeft: 15,
              paddingRight: 15,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <Typography style={{ fontWeight: 500 }}>Penilaian</Typography>
              <Typography style={{ fontWeight: 300 }}>{""}</Typography>
            </div>
            <Button
              variant="outlined"
              onClick={() => {
                toReviewList();
              }}
            >
              Lihat Semua
            </Button>
          </Grid>
          <Grid
            style={{
              marginTop: 15,
              paddingBottom: 30,
              paddingLeft: 15,
              paddingRight: 15,
              display: "flex",
              justifyContent: "center",
            }}
          >
            {lastReview ? (
              <ReviewCard data={lastReview} />
            ) : (
              <>Belum ada penilaian</>
            )}
          </Grid>
        </Box>
      ) : (
        <LocationAccess />
      )}
    </DefaultContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    userLogout: () => {
      dispatch(userLogout());
    },
  };
};

OnboardingScreen.propTypes = {
  userLogout: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingScreen);
