import { Grid, Typography } from "@mui/material";
import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import MoneyFormat from "./MoneyFormat";
import { useState } from "react";
import {Button} from "@mui/material";
import { deleteFromCart } from "../config/redux/actions/cartActions";
import { useDispatch } from "react-redux";
import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect, useRef } from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

export default function CartItemCard({ productDetails, index, programData, isUsingProgram, isUsingVoucher, voucherData}) {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [thumbnail, setThumbnail] = useState(productDetails.data.thumbnail);
  let identifierDiscount = 0;
  const [status, setStatus] = useState(productDetails?.status);

    useEffect(()=>{
        console.log(programData);

    },[programData])

    const programDataInspect = programData;
    console.log(programDataInspect);
    console.log(isUsingProgram)
    let discountItem = [];
    if(programData?.success===1){
        if(programData && programData?.data[0].discount_type==="3"){
            for (let i = 0; i < programData?.data[0].prerequisite_category.length; i++) {
                discountItem.push(programData?.data[0].prerequisite_category[i].id)
            }
        }else if (programData && programData?.data[0].discount_type==="2"){
            for (let i = 0; i < programData?.data[0].prerequisite_menu.length; i++) {
                discountItem.push(programData?.data[0].prerequisite_menu[i].id)
            }
        }
    
        if(programData && programData?.data[0].discount_type==="3"){
            identifierDiscount=1;
        }
    }
    

  const itemRef = useRef(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
      if (itemRef.current) {
          setHeight(itemRef.current.clientHeight); // Ensure `clientHeight` is only accessed when the element exists
      }
  }, []);

  const handleDelete = (productDetails) => {
      if (deleteFromCart) {
          // Call delete function with product ID
          dispatch(deleteFromCart(productDetails));
      } else {
          console.error("deleteFromCart is not defined!");
      }
  };

  return status === undefined ? (
      <Grid
          style={{ marginBottom: 20 }}
          container
          key={index}
          onClick={(e) => {
              navigate("/item-details", {
                  state: { details: productDetails },
              });
          }}
      >
          <Grid item xs={3}>
              <div
                  style={{
                      width: 75,
                      height: 75,
                      borderRadius: 12,
                      marginBottom: 15,
                      overflow: "hidden",
                  }}
              >
                  {productDetails.data.thumbnail === null ||
                  productDetails.data.thumbnail === "" ? (
                      <img
                          src={"/assets/img/image-not-found.png"}
                          alt="product"
                          width={75}
                          style={{
                              borderRadius: 12,
                          }}
                      />
                  ) : (
                      <img
                          src={thumbnail}
                          alt="product"
                          style={{
                              borderRadius: 12,
                              height: "100%",
                              width: "100%",
                              objectFit: "cover",
                              objectPosition: "center",
                          }}
                          onLoad={() => {
                              setTimeout(() => {}, 250);
                          }}
                          onError={() => {
                              setThumbnail("/assets/img/image-not-found.png");
                          }}
                      />
                  )}
              </div>
          </Grid>

          <Grid item xs={9}>
              <Typography
                  style={{
                      fontWeight: 600,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                  }}
              >
                  {productDetails.data.nama}
                  {console.log(voucherData)}
                  {console.log(voucherData.prerequisite)}
                  <span
                      style={{
                          width: "40%",
                          textAlign: "right",
                          fontSize: 13,
                          color:"#1fb0e6"
                      }}
                      hidden={
                        !(
                            programData && isUsingProgram 
                              ? identifierDiscount === 1 
                                ? discountItem.some(item => item === productDetails.data.id_category) 
                                : discountItem.some(item => item === productDetails.data.id) 
                              : isUsingVoucher
                                ? voucherData && voucherData.prerequisite // Pastikan voucherData dan prerequisite ada
                                    ? (() => {
                                        try {
                                          const prerequisite = JSON.parse(voucherData.prerequisite); // Parsing JSON dengan try-catch untuk menangani error
                                          return voucherData.type_id === "2"
                                            ? prerequisite.menu_id?.split(',').includes(productDetails.data.id) // Gunakan optional chaining (?.) agar tidak error
                                            : prerequisite.category_id?.split(',').includes(productDetails.data.id_category);
                                        } catch (error) {
                                          console.error("Error parsing voucherData.prerequisite:", error);
                                          return false; // Kembalikan false jika terjadi error
                                        }
                                      })()
                                    : true // Jika voucherData atau prerequisite tidak ada, default ke true
                                : true
                          )
                          
                        }
                  >
                        Diskon
                  </span>
              </Typography>
              <Typography
                  style={{
                      fontSize: 12,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                  }}
              >
                  <span
                      style={{
                          width: "40%",
                      }}
                  >
                      {productDetails.price}
                  </span>
                  <span
                      style={{
                          width: "20%",
                      }}
                  >
                      X {productDetails.qty}
                  </span>
                  <span
                      style={{
                          width: "40%",
                          textAlign: "right",
                          fontSize: 13,
                          fontWeight: 600,
                      }}
                  >
                      <MoneyFormat value={productDetails.totalPrice} />
                  </span>
              </Typography>
              <Typography
                  style={{
                      fontSize: 12,
                      marginTop: 5,
                      overflowWrap: "break-word",
                  }}
              >
                  {productDetails.variant.length > 0 &&
                      productDetails.variant.map((item, i) => {
                          return (
                              <Fragment key={i}>
                                  <span style={{ margin: 0 }} key={i}>
                                      {item.data_variant[0].name}
                                      {i + 1 === productDetails.variant.length ? "" : ", "}
                                  </span>
                              </Fragment>
                          );
                      })}
              </Typography>
              <Typography
                  style={{
                      fontSize: 12,
                      color: "gray",
                      width: "65%",
                      fontStyle: "italic",
                  }}
              >
                  {productDetails.notes}
              </Typography>
          </Grid>
      </Grid>
  ) : status === "Tebus Murah" ? (
      <div>
          <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography>{productDetails.data.nama}</Typography>
                  <Typography
                      style={{
                          fontSize: 12,
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                      }}
                  >
                      <span
                          style={{
                              width: "20%",
                          }}
                      >
                          X {productDetails.qty}
                      </span>
                      <span
                          style={{
                              width: "40%",
                              textAlign: "right",
                              fontSize: 13,
                              fontWeight: 600,
                          }}
                      >
                          <MoneyFormat value={productDetails.totalPrice} />
                      </span>
                      {/* <Button style={{padding:"0"}}>
                        <DeleteOutlineIcon
                            style={{ color: "black" }}
                            onClick={() => handleDelete(productDetails)}
                        />
                      </Button> */}
                  </Typography>
              </Grid>
          </Grid>
      </div>
  ) : status === "Beli X dapet Y" ? (
    <div ref={itemRef} style={{ minHeight: height ? height + "px" : "auto" }}>
        <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography>{productDetails.data.nama}</Typography>
                  <Typography
                      style={{
                          fontSize: 12,
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                      }}
                  >
                      <span
                          style={{
                              width: "20%",
                          }}
                      >
                          X {productDetails.qty}
                      </span>
                  </Typography>
              </Grid>
          </Grid>
    </div>
  ) : (
    <></>
  );
}
