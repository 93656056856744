import { useEffect, useState } from "react";
import { getSmartWaiterContent } from "../services/SmartWaiter";
import ls from "local-storage";
import { Typography, Button } from "@mui/material";
import ProductCard from "./ProductCard";

export default function SmartWaiter(props) {
    const [smartData, setSmartData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showAll, setShowAll] = useState(false);
    console.log(props.selectedVoucher.id);
    
    useEffect(() => {
        fetchSmartWaiterData();
    }, [isLoading]);

    async function fetchSmartWaiterData() {
        const data = await getSmartWaiterContent(ls.get("partnerID"));

        // SORT DATA WITH IS_RECOMMENDED FIRST, THEN ALPHABETICALLY
        const firstSort = data.recommended.sort((a, b) => parseInt(b.is_recommended) - parseInt(a.is_recommended));

        const sortedData = firstSort.sort((a, b) => a.nama.toLowerCase().localeCompare(b.nama.toLowerCase()));

        // FILTER DUPLICATE NAMES
        const filteredData = sortedData.filter((item, i) => {
            if (i === 0) return true;
            return sortedData[i - 1].nama !== item.nama && item.stock !== "0";
        });

        // FILTER ITEMS ALREADY IN CART
        const filterByCartData = filteredData.filter(item => !props.cartData.some(comp => comp.data.nama === item.nama));

        setSmartData(filterByCartData);
        setIsLoading(false);
    }

    return (
        smartData.length > 0 ? (
            <div style={{
                marginBottom: props.specialDiscount > 0 
                            ? props.selectedVoucher.id !== undefined 
                              ? "25%"  // Both specialDiscount and voucher are present
                              : "10%"  // Only specialDiscount is present
                            : props.selectedVoucher.id !== undefined 
                              ? "15%"  // Only voucher is present
                              : "0%"   // Neither specialDiscount nor voucher are present 
            }}>
                <Typography
                    style={{
                        color: "white",
                        backgroundColor: "#1fb0e6",
                        padding: "0.5rem 1rem",
                        borderTopLeftRadius: "0.5rem",
                        borderTopRightRadius: "0.5rem",
                        marginBottom: "1rem"
                    }}
                >
                    Mau Yang Ini Sekalian?
                </Typography>

                {smartData.slice(0, showAll ? smartData.length : 3).map((item, i) => (
                    <ProductCard
                        productDetails={item}
                        index={i}
                        key={i}
                        dataFrom="smartWaiter"
                    />
                ))}

                {smartData.length > 3 && (
                    <Button
                        variant="text"
                        onClick={() => setShowAll(!showAll)}
                        style={{ width:"100%", marginTop: "10px", color: "#1fb0e6", fontWeight: "bold", textTransform: "none", textAlign:"center"}}
                    >
                        {showAll ? "Tutup" : "Lihat Semua"}
                    </Button>
                )}
            </div>
        ) : (
            <div></div>
        )
    );
}