import {
  Backdrop,
  Grid,
  Paper,
  SpeedDial,
  SpeedDialAction,
  Tab,
  Tabs,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getByID } from "../services/Products";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ShoppingBasketOutlinedIcon from "@mui/icons-material/ShoppingBasketOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import ProductCard from "../components/ProductCard";
import RoomServiceIcon from "@mui/icons-material/RoomService";
import DefaultContainer from "../components/DefaultContainer";
// import SwipeableViews from "react-swipeable-views";
import { connect } from "react-redux";
import useDocumentTitle from "../config/utils/useDocumentTitle";
import DefaultHeader from "../components/DefaultHeader";
import Toast from "../components/Snackbar";
import { fCallWaiter } from "../services/Partners";
import ls from "local-storage";
import Warning from "../components/Warning";
import SkeletonOrder from "../components/SkeletonOrder";
import Badge from '@mui/material/Badge';
import { ShoppingCartRounded } from "@mui/icons-material";

const OrderScreen = ({ props, cartData, userDetail }) => {
  const navigate = useNavigate();
  const [categoryIndex, setCategoryIndex] = useState(0);
  const [partnerDetails, setPartnerDetails] = useState([]);
  const [menus, setMenus] = useState([]);
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [status, setStatus] = useState(0);
  const [width, setWindowWidth] = useState(0);
  const [height, setWindowHeight] = useState(0);
  const [isOpeningHour, setIsOpeningHour] = useState(false);
  const [msgToast, setMsgToast] = useState("");
  const [loading, setLoading] = useState(true);
  const hours = new Date().getHours();
  const minutes = new Date().getMinutes();
  const seconds = new Date().getSeconds();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString)
  const partnerID = urlParams.get('partnerID')
;
  const timeNow = `${hours > 9 ? hours : "0" + hours}:${minutes > 9 ? minutes : "0" + minutes
    }:${seconds > 9 ? seconds : "0" + seconds}`;

  // useDocumentTitle(location.state.details.name + " | Order");
  useDocumentTitle(ls.get("sfPartner").name + " | Order");
  const style = {
    width: width,
    maxWidth: 480,
    margin: 0,
    bottom: 0,
    left: "auto",
    position: "fixed",
  };

  let categories =
    menus &&
    menus.map((x, i) => {
      if (menus[i].data === null || menus[i].data === undefined) {
      } else {
        return (
          <Tab value={i} label={menus[i].category} wrapped={false} key={i} />
        );
      }
    });

  const handleChange = (event, newValue) => {
    setCategoryIndex(newValue);
  };

  async function getMenus(partnerID) {
    await getByID(partnerID).then((response) => {
      if (response.success === 1) {
        let promoAndFav = []
        let menuResProcess = response.menus

        promoAndFav = menuResProcess.filter((item, i) => {
          return item.category === "Promo" || item.category === "Favorit"
        })

        menuResProcess = menuResProcess.filter((item, i) => {
          return item.category !== "Promo" && item.category !== "Favorit"
        })
        
        // .sort((a,b) => {
        //   return a.category.localeCompare(b.category)
        // })

        menuResProcess = [...promoAndFav, ...menuResProcess]

        setMenus(menuResProcess);
        setLoading(false);
        const catIndex = menuResProcess.map((item, i) => {return {item, i}}).filter((fi, i) => fi.item.data)

        if((catIndex.length === 1 || JSON.stringify(promoAndFav) === JSON.stringify([])) && (!location?.state?.categoryIndex || (location?.state?.categoryIndex && parseInt(location?.state?.categoryIndex) < 1) ) ){
          setCategoryIndex(catIndex[0].i)
        }

      }
    });
  }

  // async function getMenusOrdered(partnerID) {
  //   await getByID(partnerID).then((response) => {
  //     if (response.success === 1) {
  //       let promoAndFav = []
  //       let menuResProcess = response.menus

  //       promoAndFav = menuResProcess.filter((item, i) => {
  //         return item.category === "Promo" || item.category === "Favorit"
  //       })

  //       menuResProcess = menuResProcess.filter((item, i) => {
  //         return item.category !== "Promo" && item.category !== "Favorit"
  //       })
  //       .sort((a,b) => {
  //         return a.category.localeCompare(b.category)
  //       })
        

  //       menuResProcess = [...promoAndFav, ...menuResProcess]

  //       setMenus(menuResProcess);
  //       setLoading(false);
  //       const catIndex = menuResProcess.map((item, i) => {return {item, i}}).filter((fi, i) => fi.item.data)
        
  //       if(catIndex.length === 1){
  //         setCategoryIndex(catIndex[0].i)
  //       }

  //     }
  //   });
  // }


  function toHistory() {

    userDetail
      ? navigate(`/history?partnerID=${ls.get("partnerID") ? ls.get("partnerID") : partnerID}`)
      : navigate(`/auth?partnerID=${ls.get("partnerID") ? ls.get("partnerID") : partnerID}`, { state: { to: "/order", from:"/history" } });
  }

  function toCart() {
    if (cartData && cartData.length > 0) {
      navigate("/cart");
    } else {
      setMsg("Cart kamu kosong");
      setStatus(0);
      setIsOpen(true);

      setTimeout(() => {
        setIsOpen(false);
      }, 1000);
    }
  }

  async function askBill() {
    var a = {
      partnerID: ls.get("partnerID"),
      tableCode: ls.get("tableID"),
      message: "Minta Bill",
    };
    await fCallWaiter(a).then((response) => {
      if (response.success === 1) {
        setStatus(response.success);
      }
      setMsg(response.msg);
      setIsOpen(!isOpen);
    });
  }

  async function callWaiter() {
    var a = {
      partnerID: ls.get("partnerID"),
      tableCode: ls.get("tableID"),
      message: "Panggilan",
    };
    await fCallWaiter(a).then((response) => {
      if (response.success === 1) {
        setStatus(response.success);
      }
      setMsg(response.msg);
      setIsOpen(!isOpen);
    });
  }

  const updateDimensions = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    setWindowWidth(width);
    setWindowHeight(height);
  };

  useEffect(() => {
    const days = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ];

    if(location?.state?.categoryIndex){
      setCategoryIndex(location.state.categoryIndex)
    }
    // console.log(ls.get("sfOpenHour") < timeNow && timeNow > ls.get("sfLastOrder") && timeNow < ls.get("sfCloseHour"))

      const thisDay = days[new Date().getDay()];
      setPartnerDetails(ls.get("sfPartner"));

      if(!ls.get("partnerID")){
        ls.set("partnerID", partnerID)
      }

      getMenus(ls.get("partnerID") ? ls.get("partnerID") : partnerID);
  
      if(!ls.get("sfOpenHour") || !ls.get("sfCloseHour") || !ls.get("sfLastOrder")){
        ls.set("sfOpenHour", ls.get("sfPartner").jam_buka)
        ls.set("sfCloseHour", ls.get("sfPartner").jam_tutup)
        ls.set("sfLastOrder", ls.get("sfHours")[`${thisDay}_last_order`])
      }

      updateDimensions();
  
      window.addEventListener("resize", updateDimensions);
  
      if (ls.get("sfOpenHour") && ls.get("sfOpenHour") < timeNow &&
        timeNow > ls.get("sfCloseHour")
        ) {
          setIsOpeningHour(false);
          setMsgToast(
            "Mohon maaf cafe sudah tutup, silahkan coba kembali besok"
          );
          return
      }
  
  
      if(ls.get("sfOpenHour") && ls.get("sfOpenHour") > timeNow){
        setIsOpeningHour(false);
        setMsgToast("Mohon maaf cafe belum buka");
        return
      }
      
      if((ls.get("sfPartner").is_temporary_close==="1")){
        setIsOpeningHour(false)
        setMsgToast ("Mohon maaf cafe sedang tutup sementara")
        return
      }
  
      if (ls.get("sfShift") === "1") {
        if (ls.get("sfOpenHour") && ls.get("sfOpenHour") < timeNow && timeNow < ls.get("sfCloseHour")) {
          setIsOpeningHour(true);
        }
      } else {
        if (ls.get("sfOpenHour") && ls.get("sfOpenHour") < timeNow &&
        timeNow > ls.get("sfLastOrder") && timeNow < ls.get("sfCloseHour")
        ) {
          setIsOpeningHour(false);
          setMsgToast(
            "Mohon maaf waktu pemesanan anda melebihi waktu last order, silahkan coba kembali besok"
          );
        return
        } else {
          if(!ls.get("sfShift")){
            setMsgToast("Scan meja kembali untuk memesan!");
          }else{
            setMsgToast("Resto belum memulai shift. Mohon hubungi kasir");
          }
          return
        }
    }
    return () => window.removeEventListener("resize", updateDimensions);
  }, [msgToast]);

  // let contents = [<h1>First Tab</h1>, <h1>Second Tab</h1>, <h1>Third Tab</h1>];

  let menuList =
    menus &&
    menus.map((x, i) => {
      // return <Tab value={i} label={menus[i].category} />;
      return (
        // <div key={i} style={{marginTop: ls.get("sfOpenHour") < timeNow && timeNow > ls.get("sfLastOrder") && timeNow < ls.get("sfCloseHour") ? "20px" : "0px" }} >
        <div key={i} style={{marginTop: msgToast && height < 1000 && width < height ? "20px" : "0px" }} >
          {menus[i].data &&
            menus[i].data.map((y, j) => {
              return (
                <ProductCard
                  productDetails={menus[i].data[j]}
                  index={j}
                  key={j}
                  categoryIndex={categoryIndex}
                  dataFrom={"order"}
                />
              );
            })}
        </div>
      );
    });

  const actionsTextStyle = {
    width: "max-content",
    margin: 0,
  };

  const actions = [
    {
      icon: <ReceiptLongOutlinedIcon />,
      name: <p style={actionsTextStyle}>Minta Bill</p>,
      key: 1,
      onClick: () => {
        askBill();
      },
    },
    {
      icon: <RoomServiceIcon />,
      name: <p style={actionsTextStyle}>Panggil Waiter</p>,
      key: 2,

      onClick: () => {
        callWaiter();
      },
    },
    {
      icon: <ShoppingCartRounded />,
      name: <p style={actionsTextStyle}>Cart</p>,
      key: 3,

      onClick: () => {
        toCart();
      },
    },
  ];

  const [open, setOpen] = React.useState(false);
  const [msg, setMsg] = React.useState("Cart kamu kosong");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <DefaultContainer loading={loading}>
      <Toast isOpen={isOpen} message={msg} status={status} />
      {loading ? (
        <SkeletonOrder />
      ) : (
        <>
          <Grid
            container
            style={{
              position: "fixed",
              justifyContent: "center",
              maxWidth: 480,
              background: "#fff",
            }}
          >
            {!isOpeningHour && <Warning msg={msgToast} />}
            <Grid
              item
              xs={12}
              style={{
                padding: 15,
                fontWeight: 500,
                fontSize: 20,
                textAlign: "center",
                background: "#fff",
                wordWrap: "breakWord",
                // textOverflow: "ellipsis",
                // overflow: "hidden",
              }}
            >
              <DefaultHeader
                title={partnerDetails.name}
                url={`/?partnerID=${ls.get("partnerID")}&tableID=${ls.get(
                  "tableID"
                )}`}
                trailing={
                  <ReceiptLongOutlinedIcon
                    onClick={(e) => {
                      toHistory();
                    }}
                  />
                }
              />
            </Grid>
            <Grid item xs={11}>
              <Tabs
                value={categoryIndex}
                onChange={(e, v) => {
                  handleChange(e, v);
                }}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                style={{ backgroundColor: "white" }}
              >
                {categories}
              </Tabs>
            </Grid>
            <Grid
              item
              xs={1}
              onClick={(e) => {
                navigate("/search", {});
              }}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "white"
              }}
            >
              <SearchOutlinedIcon fontSize="medium" color="primary" />
            </Grid>
          </Grid>
          <Grid
            style={{ paddingTop: isOpeningHour ? 108 : 156.02 }}
          >
            {/* <SwipeableViews
          index={categoryIndex}
          onChangeIndex={(x) => setCategoryIndex(x)}
          enableMouseEvents
          animateHeight
        >
          <Paper style={{ padding: 15 }}>{menuList[value]}</Paper>
        </SwipeableViews> */}
            <Paper
              elevation={0}
              style={{
                // marginTop:
                //   sessionStorage.getItem("sfBanner") === "open" ? 30 : 30,
                padding: 15,
                zIndex: "modal",
                marginBottom: 45,
                height: "100%",
              }}
            >
              {menuList[categoryIndex]}
            </Paper>
          </Grid>
          <div
            style={style}
          >
            <Backdrop open={open} />
            <SpeedDial
              hidden={isOpeningHour ? false : true}
              sx={{
                position: "absolute", bottom: 16
                , right: 16
              }}
              ariaLabel="SpeedDial tooltip example"
              icon={
                <>
                  <Badge
                    badgeContent={cartData.length}
                    color="error"
                    style={{
                      position: 'absolute',
                      top: 10,
                      right: 10,
                    }}
                  />
                  <ShoppingCartRounded color="white" />
                </>
              }
              onClose={handleClose}
              onOpen={handleOpen}
              open={open}
            >
              {actions.map((action) => (
                <SpeedDialAction
                  key={action.key}
                  icon={action.icon}
                  tooltipTitle={action.name}
                  tooltipOpen
                  onClick={action.onClick}
                />
              ))}
            </SpeedDial>
          </div>
        </>
      )}
    </DefaultContainer>
  );
};
const mapStateToProps = (state) => {
  return {
    currency: state.currencyData,
    // cartData: state.cartData,
    cartData: state.cartData.filter(
      (data) => data.partnerID === ls.get("partnerID")
    ),
    userDetail: state.userData[state.userData.length - 1],
    wishlistData: state.wishlistData,
    compareData: state.compareData,
  };
};

export default connect(mapStateToProps, null)(OrderScreen);
