import { useEffect, useState } from "react";
import { getPrograms } from "../services/Programs";
import { search, getByID } from "../services/Products";
import ls from "local-storage";
import { Typography, Checkbox, Dialog, DialogTitle, DialogContent, DialogActions, Button, FormControlLabel } from "@mui/material";
import { forEach, max, update } from "lodash";
import { useDispatch } from "react-redux";
import MoneyFormat from "./MoneyFormat";
import { deleteFromCart } from "../config/redux/actions/cartActions";
import axios from "axios";
import { useMediaQuery } from "@mui/material";
export default function Program(props,
    ) {
    
    const {addToCart,setSelectedVoucher, setMsg, setStatus, setIsOpen,isSmartWaiterFetching,getSmartWaiterData} = props;
    const [cartData, setCartData] = useState(ls.get('redux_localstorage_simple').cartData || []);
    const dispatch = useDispatch();
    const [programData, setProgramData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showAll, setShowAll] = useState(false);
    const [selectedProgramId, setSelectedProgramId] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [buttonClicked, setButtonClicked] = useState(false)
    const [today, setToday] = useState([]);
    const [openDialogMenu, setOpenDialogMenu] = useState(false);
    const [categoryMenuData, setCategoryMenuData] = useState([])
    const [customQty, setCustomQty] = useState([])
    const [multiplication, setMultiplication] = useState(1)
    const [dynamicQty, setDynamicQty] = useState(1);
    const isMobile = useMediaQuery("(max-width:600px)");
    const width = window.innerWidth;

    const handleCustomQty = (itemSelected, qty) => {
        console.log(itemSelected);
        let max_item = itemSelected.max_qty; // Max quantity allowed for the category
    
        setSelectedProgramId((prevSelected) => {
            // Calculate total quantity of all selected items *excluding* the current one
            let totalOtherQty = prevSelected
                .filter(item => item.id !== itemSelected.id) // Exclude current item
                .reduce((sum, item) => sum + item.quantity, 0); // Sum up the quantities
            
            // Max quantity that the selected item can take without exceeding max_item
            let remainingQuota = max_item - totalOtherQty;
    
            // If no quota left, prevent adding a new menu
            if (remainingQuota <= 0 && !prevSelected.some(item => item.id === itemSelected.id)) {
                return prevSelected; // Do nothing
            }
    
            return prevSelected.map((item) =>
                item.id === itemSelected.id 
                    ? { 
                        ...item, 
                        quantity: Math.max(Math.min(item.quantity + qty, remainingQuota),1) // Keep within limits
                    }
                    : item // Return unchanged item
            );
        });
    };
    
    

    
    useEffect(() => {
        fetchProgramData();
        const dateFunction = () => {
            const now = new Date();
            const dayNames = ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"];
            const currentDay = dayNames[now.getDay()].toUpperCase();
            const currentTime = now.toTimeString().slice(0, 5); // Format "HH:MM"
            setToday({ day: currentDay, time: currentTime });
        }
        dateFunction()
    },[isLoading]); // Ensure useEffect runs only once on mount
    
    useEffect(() =>{
     if(props.selectedVoucher.id!==undefined){
        setSelectedProgramId([])
     }   
    }, [props.selectedVoucher])

    const handleUnsetPromo = (object) => {
    let cartDataProgram = ls.get("redux_localstorage_simple")?.cartData
    if(object==="program"){
        for(const data of cartDataProgram){
        dispatch(deleteFromCart(data))
        }
    }
    if(object==="voucher"){
        setSelectedVoucher([])
    }
    setStatus(1)
    if(props.selectedVoucher.id!==undefined){
        setMsg("Berhasil mengubah promo");
    }else{
        setMsg("success")
    }
    setTimeout(() => {
        setIsOpen(true);
    
        // Hide after 3 seconds (adjust timing as needed)
        setTimeout(() => {
          setIsOpen(false);
        }, 3000);
    
      }, 300);
    }

    async function fetchProgramData() {
        const data = await getPrograms(ls.get("partnerID"));
        setProgramData(data);
        setIsLoading(false);
    }
    //to get menu data
    async function fetchMenu(name,partnerId){
        const data = await search(name,partnerId);
        return data
    }

    //khusus buat prerequisite program XY
    const checkPrerequisitesMet = (programData, cartData) => {
        if (!programData?.data?.[0]) return false; // If no program data, return false
        const program = programData.data[0];
        const prerequisiteCategories = program.prerequisite_category ?? [];
        const prerequisiteMenus = program.prerequisite_menu ?? [];
        const andOr = Number(program.and_or); // Ensure it's a number
    
        const matchedCategoriesCount = prerequisiteCategories.filter(category => {
            const totalQty = cartData
                .filter(cartItem => cartItem.data.id_category === category.id)
                .reduce((sum, cartItem) => sum + cartItem.qty, 0); // Sum all matching items' qty

            return totalQty >= category.qty; // Ensure the total quantity matches the requirement
        }).length;
    
        // Count matched prerequisite menus in the cart
        const matchedMenusCount = prerequisiteMenus.filter(menu =>
            cartData.some(cartItem => cartItem.id === menu.id)
        ).length;
    
        // Logic based on `and_or`
        if (andOr === 1) {
            for(const data of cartData){
                if((matchedCategoriesCount > 0 || matchedMenusCount > 0)===false){
                    if(data.status==="Beli X dapet Y"){
                        dispatch(deleteFromCart(data))
                    }
                }  
            }
            return matchedCategoriesCount > 0 || matchedMenusCount > 0; // OR condition
        } else {
            for(const data of cartData){
                if((matchedCategoriesCount === prerequisiteCategories.length &&
                    matchedMenusCount === prerequisiteMenus.length)===false){
                    if(data.status==="Beli X dapet Y"){
                        dispatch(deleteFromCart(data))
                    }
                }  
            }
            return matchedCategoriesCount === prerequisiteCategories.length &&
                   matchedMenusCount === prerequisiteMenus.length; // AND condition
        }
    };

    //handle select tebus murah
    const handleSelectTebusMurah = (program, maxItem) => {
        // Cek apakah program sudah ada dalam daftar terpilih
        setSelectedProgramId((prevSelected) => {
            if (prevSelected.includes(program)) {
                // Jika sudah dipilih, hapus dari daftar terpilih
                return prevSelected.filter((id) => id !== program);
            } else {
                // Jika belum mencapai batas qty_redeem, tambahkan ke daftar terpilih
                if (prevSelected.length < maxItem) {
                    return [...prevSelected, program];
                } else {
                    setOpenDialog(true);
                    return prevSelected;
                }
            }
        });
    };
    //handle select beli x dapet y
    const handleSelectBeliXDapatY = (menus) => {
        setSelectedProgramId((prevSelected) => {
            const menuIds = menus.map(menu => menu);
    
            // Check if all menu items are already selected
            const allSelected = menuIds.every(id => prevSelected.includes(id));
    
            if (allSelected) {
                // If already selected, remove them
                return prevSelected.filter(id => !menuIds.includes(id));
            } else {
                // Otherwise, add all menu items
                return [...new Set([...prevSelected, ...menuIds])]; // Ensure unique values
            }
        });
    };

    const handleSelectBeliXDapatYCategoryMenu = (menu, categoryId, maxItem) => {
        setSelectedProgramId((prevSelected) => {
            // Get selected items for the current category
            const selectedInCategory = prevSelected.filter((item) => item.id_category === categoryId);
    
            // Get the total quantity already selected for this category
            const totalSelectedQty = selectedInCategory.reduce((sum, item) => sum + (item.quantity || 1), 0);
    
            if (selectedInCategory.some((selectedMenu) => selectedMenu.id === menu.id)) {
                // If already selected, remove it
                return prevSelected.filter((selectedMenu) => selectedMenu.id !== menu.id);
            } else {
                // If the total quantity reaches maxItem, open dialog & prevent adding
                if (totalSelectedQty >= maxItem) {
                    setOpenDialog(true);
                    return prevSelected; // Don't add new item
                }
    
                // Add new item while keeping within maxItem constraints
                return [...prevSelected, { ...menu, categoryId, quantity: 1 }];
            }
        });
    };
    

    const handleSelectBeliXDapatYCategory = async (categories, max_qty) => {
        const responseCategory = await getByID(ls.get("partnerID"))
        let categoryData = responseCategory.menus.filter(category=>category.category === categories.name)
        
        // Add max_qty to each item in categoryData[0].data
        const updatedData = categoryData[0].data.map(item => ({
            ...item,
            max_qty: multiplication===0 ? max_qty : max_qty * multiplication,  // Insert max_qty
            quantity:1
        }))
        setCategoryMenuData(updatedData)
        
        setOpenDialogMenu(true);
        
    };

    const handleAddToCartTebusMurah = async () => {
        if (selectedProgramId.length === 0) return; // Skip if nothing is selected
    
        const partnerID = ls.get('sfPartner')?.id;
        const transactionType = ls.get("transactionType");
        const existingCart = ls.get("redux_localstorage_simple")?.cartData.filter( item => item.status ==="Tebus Murah") || [];
        
        // Get total programs already in cart with the same master_program_id
        const existingProgramsCount = existingCart.filter(
            item => item.status==="Tebus Murah"
        ).length;
    
        if (existingProgramsCount >= programData.data[0].qty_redeem) {
            setOpenDialog(true);
            return;
        }
    
        for (const program of selectedProgramId) {
            const existingItem = existingCart.find((item) => item.id === program.id);
            
            if (existingItem) {
                setOpenDialog(true);
                continue;
            }
    
            try {
                const fullMenu = await fetchMenu(program.name, partnerID);
    
                let item = {
                    partnerID: partnerID,
                    transactionType: transactionType,
                    isSmartWaiter: 0,
                    id: program.id,
                    name: program.name,
                    qty: program.qty,
                    notes: "",
                    image: "",
                    price: parseInt(program.price),
                    totalPrice: parseInt(program.price),
                    variant: [],
                    is_program:programData.data[0].id,
                    status: programData.data[0].master_program_id==="1" ? "Tebus Murah":"",
                    data: fullMenu?.menus?.[0] || null,
                };
    
                addToCart(item);
            } catch (error) {
                console.error("Error fetching menu:", error);
            }
        }
    
    };
    
    const handleAddToCartBeliXDapatY = async () => {
        if (selectedProgramId.length === 0) return; // Skip if nothing is selected
    
        const partnerID = ls.get('sfPartner')?.id;
        const transactionType = ls.get("transactionType");
        const existingCart = ls.get("redux_localstorage_simple")?.cartData || [];
        const multiplication = checkPrerequisitesMetMultiple(programData,existingCart)
        // Get total programs already in cart with the same master_program_id
        const existingProgramsCount = existingCart.filter(
            item => item.status==="Beli X dapet Y"
        ).length;
    
        if (existingProgramsCount >= programData.data[0].menus.length) {
            setOpenDialog(true);
            return;
        }
    
        for (const program of selectedProgramId) {
            const existingItem = existingCart.find((item) => item.id === program.id);
            
            if (existingItem) {
                setOpenDialog(true);
                continue;
            }
    
            try {
                const fullMenu = await fetchMenu(program.name, partnerID);
    
                let item = {
                    partnerID: partnerID,
                    transactionType: transactionType,
                    isSmartWaiter: 0,
                    id: program.id,
                    name: program.name,
                    qty: program.qty * multiplication,
                    notes: "",
                    image: "",
                    price: parseInt(program.price),
                    totalPrice: parseInt(program.price),
                    variant: [],
                    is_program:programData.data[0].id,
                    status: programData.data[0].master_program_id==="3" ? "Beli X dapet Y":"",
                    data: fullMenu?.menus?.[0] || null,
                };
    
                addToCart(item);
            } catch (error) {
                console.error("Error fetching menu:", error);
            }
        }
    
        // Disable button after adding
        setButtonClicked(true);
    };

    // const handleAddToCartBeliXDapatYCategory = async () => {
    //     if (selectedProgramId.length === 0) return; // Skip if nothing is selected
    
    //     const partnerID = ls.get("sfPartner")?.id;
    //     const transactionType = ls.get("transactionType");
    //     const existingCart = ls.get("redux_localstorage_simple")?.cartData || [];
    
    //     // Get categories from the program
    //     const programCategories = programData.data[0].categories;
    
    //     // Count existing items in the cart grouped by id_category
    //     let existingProgramsByCategory = {};
    //     existingCart
    //         .filter(item => item.status === "Beli X dapet Y")
    //         .forEach(item => {
    //             if (!existingProgramsByCategory[item.id_category]) {
    //                 existingProgramsByCategory[item.id_category] = 0;
    //             }
    //             existingProgramsByCategory[item.id_category]++;
    //         });
    
    //     // Check if adding the selected items exceeds the allowed qty for each category
    //     for (const program of selectedProgramId) {
    //         const category = programCategories.find(cat => cat.id === program.id_category);
            
    //         if (category) {
    //             const existingCount = existingProgramsByCategory[program.id_category] || 0;
    //             if (existingCount > category.qty && existingCount.id_category === category.id) {
    //                 setOpenDialog(true);
    //                 return;
    //             }
    //         }
    
    //         try {
    //             const fullMenu = await fetchMenu(program.nama, partnerID);
    //             if(fullMenu.success===1){
    //                 let item = {
    //                     partnerID: partnerID,
    //                     transactionType: transactionType,
    //                     isSmartWaiter: 0,
    //                     id: program.id,
    //                     name: program.name,
    //                     qty: 1,
    //                     notes: "",
    //                     image: "",
    //                     price: parseInt(program.price),
    //                     totalPrice: parseInt(program.price),
    //                     variant: [],
    //                     is_program: programData.data[0].id,
    //                     status: programData.data[0].master_program_id === "3" ? "Beli X dapet Y" : "",
    //                     id_category: program.id_category, // Ensure id_category is stored
    //                     data: fullMenu?.menus?.[0] || null,
    //                 };
                    
    //                 addToCart(item);
    //             }
    //         } catch (error) {
    //             console.error("Error fetching menu:", error);
    //         }
    //     }
    
    //     // Disable button after adding
    //     setButtonClicked(true);
    // };
    
    const handleAddToCartBeliXDapatYCategory = async () => {
        if (selectedProgramId.length === 0) return; // Skip if nothing is selected
    
        const partnerID = ls.get("sfPartner")?.id;
        const transactionType = ls.get("transactionType");
        const existingCart = ls.get("redux_localstorage_simple")?.cartData || [];
    
        // Get categories from the program
        const programCategories = programData.data[0].categories;
    
        // Count existing items in the cart per category
        let existingProgramsByCategory = {};
        existingCart
            .filter(item => item.status === "Beli X dapet Y")
            .forEach(item => {
                if (!existingProgramsByCategory[item.id_category]) {
                    existingProgramsByCategory[item.id_category] = 0;
                }
                existingProgramsByCategory[item.id_category] += item.qty; // Count total qty per category
            });
    
        // Process each selected item individually
        for (const program of selectedProgramId) {
            const category = programCategories.find(cat => cat.id === program.id_category);
            if (!category) continue;
    
            const existingQty = existingProgramsByCategory[program.id_category] || 0;
    
            // 🔥 Check if adding this item exceeds the category limit
            if (existingQty >= category.qty * multiplication) { 
                setOpenDialog(true);
                return; // Stop only this item, but allow other categories to be added
            }
    
            try {
                const fullMenu = await fetchMenu(program.nama, partnerID);
                if (fullMenu.success === 1) {
                    let item = {
                        partnerID: partnerID,
                        transactionType: transactionType,
                        isSmartWaiter: 0,
                        id: program.id,
                        name: program.name,
                        qty: program.quantity ? program.quantity : 1, // Default qty 1
                        notes: "",
                        image: "",
                        price: parseInt(program.price),
                        totalPrice: parseInt(program.price),
                        variant: [],
                        is_program: programData.data[0].id,
                        status: programData.data[0].master_program_id === "3" ? "Beli X dapet Y" : "",
                        id_category: program.id_category, // Ensure id_category is stored
                        data: fullMenu?.menus?.[0] || null,
                    };
    
                    addToCart(item);
    
                    // 🔄 Update the count AFTER adding the item
                    existingProgramsByCategory[program.id_category] = (existingProgramsByCategory[program.id_category] || 0) + 1;
                }
            } catch (error) {
                console.error("Error fetching menu:", error);
            }
        }
    
        // Disable button after adding
        setButtonClicked(true);
    };
    
    // const handleIsMultipleMenu = () => {
    //     const cartData = ls.get('redux_localstorage_simple').cartData;
    //     let multipleVal = 1;
    //     let checkCart;
    //     const qty_prerequisite = programData[0].data?.prerequisite_menu ? programData[0].data?.prerequisite_menu : []
    //     for (const menu of qty_prerequisite){
    //         checkCart = cartData.filter((item)=> item.id === menu.id)
    //     }
    //     return checkCart
    // }
    
    const checkPrerequisitesMetMultiple = (programData, cartData) => {
        if (!programData?.data?.[0]) return { isMet: false, multiplier: 0 };
    
        const program = programData.data[0];
        const prerequisiteCategories = program.prerequisite_category ?? [];
        const prerequisiteMenus = program.prerequisite_menu ?? [];
        const andOr = Number(program.and_or); // Ensure it's a number
        const multiple_status = programData.data[0].is_multiple
        
    
        let categoryMultipliers = [];
        let menuMultipliers = [];
    
        // Check prerequisite categories in the cart
        const matchedCategoriesCount = prerequisiteCategories.filter(category => {
            const totalQty = cartData
                .filter(cartItem => cartItem.data.id_category === category.id)
                .reduce((sum, cartItem) => sum + cartItem.qty, 0);
    
            if (totalQty >= category.qty) {
                categoryMultipliers.push(Math.floor(totalQty / category.qty)); // Store multiplier for each category
                return true;
            }
            return false;
        }).length;
    
        // Check prerequisite menus in the cart
        const matchedMenusCount = prerequisiteMenus.filter(menu => {
            const menuItem = cartData.find(cartItem => cartItem.id === menu.id);
            if (menuItem) {
                menuMultipliers.push(Math.floor(menuItem.qty / menu.qty)); // Store multiplier for each menu
                return true;
            }
            return false;
        }).length;
    
        let multiplier = 0;
    
        if (andOr === 1) { // OR condition
            const isMet = matchedCategoriesCount > 0 || matchedMenusCount > 0;
            if (isMet) {
                multiplier = Math.max(...categoryMultipliers, ...menuMultipliers, 1); // Pick the highest multiplier
            }
            
            cartData.forEach(data => {
                if (!isMet && data.status === "Beli X dapet Y") {
                    dispatch(deleteFromCart(data));
                }
            });
            if(multiple_status==="1"){
                return multiplier;
            }else{
                return 1
            }
        } else { // AND condition
            const isMet = matchedCategoriesCount === prerequisiteCategories.length &&
                          matchedMenusCount === prerequisiteMenus.length;
            
            if (isMet) {
                multiplier = Math.min(...categoryMultipliers, ...menuMultipliers); // Pick the lowest multiplier
            }
    
            cartData.forEach(data => {
                if (!isMet && data.status === "Beli X dapet Y") {
                    dispatch(deleteFromCart(data));
                }
            });
            if(multiple_status==="1"){
                return multiplier;
            }else{
                return 1
            }
        }
    };

    useEffect(() => {
        const multiplier = checkPrerequisitesMetMultiple(programData, cartData);
            setMultiplication(multiplier);
    }, [programData,cartData]); // Dependencies: only runs when `programData` or `cartData` changes

    console.log(getSmartWaiterData(),isSmartWaiterFetching());
    

{/* code tampilan program Tebus Murah */}

    return programData.data?.length > 0 && programData.data[0].master_program_id === "1" 
    &&
    (
        (
            (
                (programData.data[0]?.day?.length > 0 || 
                (programData.data[0]?.start_hour !== "00:00:00" && programData.data[0]?.end_hour !== "00:00:00"))
                &&
                (programData.data[0]?.end_hour >= today.time && today.time >= programData.data[0]?.start_hour ||
                programData.data[0]?.day?.some(dayObj => dayObj.value === today.day))
            )
            || // Tambahkan fallback jika day = null & jam = "00:00:00"
            (programData.data[0]?.day == null && 
             programData.data[0]?.start_hour === "00:00:00" && 
             programData.data[0]?.end_hour === "00:00:00")
        )
    )
    ? (
        <div>
            {props.calculateData.subtotal >= programData?.data[0].minimum_value ? 
                <Typography
                    style={{
                        color: "white",
                        backgroundColor: "#1fb0e6",
                        padding: "0.5rem 1rem",
                        borderTopLeftRadius: "0.5rem",
                        borderTopRightRadius: "0.5rem",
                        marginBottom: "1rem"
                    }}
                >
                    {programData.data[0].title}
                </Typography>
                :
                <Typography
                    style={{
                        color: "white",
                        backgroundColor: "#9f9f9f",
                        padding: "0.5rem 1rem",
                        borderTopLeftRadius: "0.5rem",
                        borderTopRightRadius: "0.5rem",
                        marginBottom: "1rem"
                    }}
                >
                    {programData.data[0].title}
                </Typography>
            }

            {programData.data.map((item) => (
                <div key={item.id} style={{ padding: "0.5rem", borderBottom: "1px solid #ddd" }}>
                    {item.menus.map((menu) => (
                        <div
                            key={menu.id}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                padding: "0.5rem 0",
                                pointerEvents: props.calculateData.subtotal >= programData?.data[0].minimum_value ? 'auto' : 'none'
                            }}
                            onClick={() => handleSelectTebusMurah(menu, item.qty_redeem)}
                        >
                            <Checkbox
                                disabled={props.calculateData.subtotal >= programData?.data[0].minimum_value ? false : true}
                                checked={selectedProgramId.includes(menu)}
                                onChange={() => handleSelectTebusMurah(menu, item.qty_redeem)}
                                style={{zIndex:'-1'}}
                            />
                            <div style={{ flex: 1, paddingLeft: "1rem" }}>
                                <Typography variant="body1">{menu.qty} X {menu.name}</Typography>
                                <Typography variant="body2">
                                    Minimum pembelian <MoneyFormat value={item.minimum_value}/>
                                </Typography>
                            </div>
                            <div style={{ textAlign: "right" }}>
                                <Typography 
                                    variant="body1" 
                                    sx={{ textDecoration: "line-through", color: "gray" }}
                                >
                                    <MoneyFormat value={menu.real_price}/>
                                </Typography>
                                <Typography variant="body2">
                                    <MoneyFormat value={menu.price}/>
                                </Typography>
                            </div>
                        </div>
                    ))}
                </div>
            ))}
            <div style={{pointerEvents: selectedProgramId.length ===0 || (props.calculateData.subtotal >= programData?.data[0].minimum_value) ? 'auto' : 'none'}} onClick={() => {handleAddToCartTebusMurah(); handleUnsetPromo('voucher')}}>
                <Button style={{
                    width:"100%",
                    backgroundColor: selectedProgramId.length === 0 || selectedProgramId.length > programData.data[0].qty_redeem 
                    ? "gray" 
                    : "#1fb0e6",
                    color:"white",
                    "&:disabled": { backgroundColor: "gray", color: "white" },
                    zIndex:'-1'
                }} 
                variant="contained" color="primary" onClick={() => {handleAddToCartTebusMurah(); handleUnsetPromo('voucher')}} disabled={selectedProgramId.length ===0 ||  selectedProgramId.length > programData.data[0].qty_redeem}>
                    <Typography>Tambahkan ke Keranjang</Typography>
                </Button>
            </div>
            
            {/* Popup Dialog */}
            <Dialog
             fullWidth
             sx={{
                 "& .MuiDialog-paper": {
                     width: isMobile ? "100%" : `${(width*25)/100}`,
                     maxWidth: "480px", // Hindari batas max width bawaan MUI
                     margin: isMobile ? "0" : "auto", // Agar tetap di tengah di PC
                 }
             }}
             open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>Informasi</DialogTitle>
                <DialogContent>
                    <Typography>Hanya {programData.data[0].qty_redeem} program yang bisa digunakan!</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)} color="secondary">
                        Tutup
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
    // {/* code tampilan program XY */}
    : programData.data?.length > 0 && programData?.data[0].master_program_id === "3" &&
    (
        (
            (
                (programData.data[0]?.day?.length > 0 || 
                (programData.data[0]?.start_hour !== "00:00:00" && programData.data[0]?.end_hour !== "00:00:00"))
                &&
                (programData.data[0]?.end_hour >= today.time && today.time >= programData.data[0]?.start_hour ||
                programData.data[0]?.day?.some(dayObj => dayObj.value === today.day))
            )
            || // Tambahkan fallback jika day = null & jam = "00:00:00"
            (programData.data[0]?.day == null && 
             programData.data[0]?.start_hour === "00:00:00" && 
             programData.data[0]?.end_hour === "00:00:00")
        )
    ) ? (
        <div>
            {(
                (
                (programData.data[0]?.prerequisite_category?.length > 0 || 
                programData.data[0]?.prerequisite_menu?.length > 0)
                ) &&
                (
                    checkPrerequisitesMet(programData, ls.get("redux_localstorage_simple")?.cartData || [])
                )
            ) ? 
                <Typography
                    style={{
                        color: "white",
                        backgroundColor: "#1fb0e6",
                        padding: "0.5rem 1rem",
                        borderTopLeftRadius: "0.5rem",
                        borderTopRightRadius: "0.5rem",
                        marginBottom: "1rem"
                    }}
                >
                    {programData.data[0].title}
                </Typography>
                :
                <Typography
                    style={{
                        color: "white",
                        backgroundColor: "#9f9f9f",
                        padding: "0.5rem 1rem",
                        borderTopLeftRadius: "0.5rem",
                        borderTopRightRadius: "0.5rem",
                        marginBottom: "1rem"
                    }}
                >
                    {programData.data[0].title}
                </Typography>
            }

{programData.data.map((item) => (
                <div key={item.id} style={{ padding: "0.5rem", borderBottom: "1px solid #ddd" }}>
                        <div>
                            {programData?.data[0]?.prerequisite_category !== null ? (
                                <Typography>
                                    Beli{" "}
                                    {programData.data[0].prerequisite_category
                                        .map((item) => `${item.qty} dari produk ${item.name}`)
                                        .join(programData.data[0].and_or===1 ? " atau ":" dan ")}
                                    {" "}dapatkan produk dibawah ini secara gratis :
                                </Typography>
                            ) : (
                                <Typography>
                                    Beli{" "}
                                    {programData.data[0].prerequisite_menu
                                        .map((item) => `${item.qty} ${item.name}`)
                                        .join(programData.data[0].and_or===1 ? " atau ":" dan ")}
                                    {" "}dapatkan produk dibawah ini secara gratis :
                                </Typography>
                            )}
                        </div>
                        {item.menus !== null ? (
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    padding: "0.5rem 0",
                                    marginBottom:isSmartWaiterFetching()===false && getSmartWaiterData()===null && props.selectedVoucher.id!==undefined ? "15%":"0",
                                    pointerEvents:checkPrerequisitesMet(programData, cartData) ? 'auto':'none'
                                }}
                                onClick={() => handleSelectBeliXDapatY(item.menus, item.qty_redeem)}
                            >
                                <Checkbox
                                    disabled={(() => {
                                        const program = programData.data[0];
                                        const cartData = ls.get("redux_localstorage_simple")?.cartData || [];
                                    
                                        if (!program) return true; // If no program data, disable the button
                                    
                                        const prerequisiteCategories = program.prerequisite_category ?? [];
                                        const prerequisiteMenus = program.prerequisite_menu ?? [];
                                        const andOr = Number(program.and_or); // Ensure it's a number
                                    
                                        // Count matched prerequisite categories in the cart
                                        // const matchedCategoriesCount = prerequisiteCategories.filter(category =>
                                        //     cartData.some(cartItem => 
                                        //         cartItem.data.id_category === category.id && cartItem.qty >= category.qty
                                        //     )
                                        // ).length;
                                        // Count matched prerequisite categories in the cart
                                        const matchedCategoriesCount = prerequisiteCategories.filter(category => {
                                            const totalQty = cartData
                                                .filter(cartItem => cartItem.data.id_category === category.id)
                                                .reduce((sum, cartItem) => sum + cartItem.qty, 0); // Sum all matching items' qty

                                            return totalQty >= category.qty; // Ensure the total quantity matches the requirement
                                        }).length;
                                    
                                        // Count matched prerequisite menus in the cart
                                        const matchedMenusCount = prerequisiteMenus.filter(menu =>
                                            cartData.some(cartItem => cartItem.id === menu.id && cartItem.qty >= menu.qty)
                                        ).length;
                                    
                                        // Logic based on `and_or`
                                        if (andOr === 1) {
                                            // OR condition: At least one category OR one menu must match
                                            return !(matchedCategoriesCount > 0 || matchedMenusCount > 0);
                                        } else {
                                            // AND condition: All prerequisite categories AND all menus must be fulfilled
                                            return !(matchedCategoriesCount === prerequisiteCategories.length &&
                                                     matchedMenusCount === prerequisiteMenus.length);
                                        }
                                    })()}
                                    checked={selectedProgramId.some(selectedMenu => item.menus.some(menu => menu.id === selectedMenu.id))}
                                    onChange={() => handleSelectBeliXDapatY(item.menus, item.qty_redeem)}
                                    style={{zIndex:'-1'}}
                                />
                                <div style={{ flex: 1, paddingLeft: "1rem" }}>
                                    <Typography variant="body1">
                                        {item.menus
                                            .map((menu) => `${parseInt(multiplication)===0?  parseInt(menu.qty) : parseInt(menu.qty) * parseInt(multiplication)} X ${menu.name}`)
                                            .join(", ")
                                            .replace(/,([^,]*)$/, " dan$1") // Replace the last comma with 'and'
                                        }
                                    </Typography>
                                </div>
                            </div>
                        ):
                        (item.categories.map((category,index)=>(
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    padding: "0.5rem 0",
                                    marginBottom:isSmartWaiterFetching()===false && getSmartWaiterData()===null && props.selectedVoucher.id!==undefined && index===item.categories.length-1 ? "15%":"0"
                                }}
                            >
                                <div style={{
                                    pointerEvents: checkPrerequisitesMet(programData,cartData) ? 'auto':"none",
                                    width:"100%",
                                }}
                                onClick={()=>  handleSelectBeliXDapatYCategory(category,category.qty)}
                                >
                                    <Button
                                        style={{
                                            backgroundColor:"white",
                                            width:"100%",
                                            zIndex:'-1'
                                            // marginBottom :  props.specialDiscount>0 && index===item.categories.length-1   ? "50%" : props.selectedVoucher?.id !== undefined && index===item.categories.length-1 ? "10%" : "0" 
                                            // marginBottom:index===item.categories.length-1 && "15%"
                                        }}
                                        onClick={()=>  handleSelectBeliXDapatYCategory(category,category.qty)}
                                        disabled={(() => {
                                            const program = programData.data[0];
                                            const cartData = ls.get("redux_localstorage_simple")?.cartData || [];
                                        
                                            if (!program) return true; // If no program data, disable the button
                                        
                                            const prerequisiteCategories = program.prerequisite_category ?? [];
                                            const prerequisiteMenus = program.prerequisite_menu ?? [];
                                            const andOr = Number(program.and_or); // Ensure it's a number
                                        
                                            // Count matched prerequisite categories in the cart
                                            const matchedCategoriesCount = prerequisiteCategories.filter(category =>
                                                cartData.some(cartItem => 
                                                    cartItem.data.id_category === category.id && cartItem.qty >= category.qty
                                                )
                                            ).length;
                                        
                                            // Count matched prerequisite menus in the cart
                                            const matchedMenusCount = prerequisiteMenus.filter(menu =>
                                                cartData.some(cartItem => cartItem.id === menu.id && cartItem.qty >= menu.qty)
                                            ).length;
                                        
                                            // Logic based on `and_or`
                                            if (andOr === 1) {
                                                // OR condition: At least one category OR one menu must match
                                                return !(matchedCategoriesCount > 0 || matchedMenusCount > 0);
                                            } else {
                                                // AND condition: All prerequisite categories AND all menus must be fulfilled
                                                return !(matchedCategoriesCount === prerequisiteCategories.length &&
                                                        matchedMenusCount === prerequisiteMenus.length);
                                            }
                                        })()}                                                                    
                                        // checked={selectedProgramId.some(selectedMenu => item.categories.some(categories => categories.id === selectedMenu.id))}
                                        // onClick={() => handleSelectBeliXDapatYCategory(category,category.qty)}
                                    >
                                        <Typography variant="body1">
                                            {`${multiplication===0 ? category.qty : category.qty * multiplication} X ${category.name}` 
                                            }
                                        </Typography>
                                    </Button>
                                </div>
                                
                                {/* Dialog Box */}
                                <Dialog
                                    open={openDialogMenu}
                                    onClose={() => setOpenDialogMenu(false)}
                                    fullWidth
                                    sx={{
                                        "& .MuiDialog-paper": {
                                            width: isMobile ? "100%" : `${(width*25)/100}`,
                                            maxWidth: "480px", // Hindari batas max width bawaan MUI
                                            margin: isMobile ? "0" : "auto", // Agar tetap di tengah di PC
                                        }
                                    }}
                                >

                                        <DialogTitle>Pilih Menu</DialogTitle>
                                        <DialogContent>
                                        {categoryMenuData ? (
                                            categoryMenuData.map((item) => (
                                                <div key={item.id}>
                                                        <FormControlLabel
                                                            key={item.id}
                                                            control={
                                                                <Checkbox
                                                                    disabled={(
                                                                        (
                                                                        (programData.data[0]?.prerequisite_category?.length > 0 || 
                                                                        programData.data[0]?.prerequisite_menu?.length > 0)
                                                                        ) &&
                                                                        (
                                                                        ls.get("redux_localstorage_simple")?.cartData.some(cartItem =>
                                                                            (programData.data[0]?.prerequisite_category ?? []).some(program =>
                                                                            cartItem.data.id_category === program.id
                                                                            )
                                                                        ) ||
                                                                        ls.get("redux_localstorage_simple")?.cartData.some(cartItem =>
                                                                            (programData.data[0]?.prerequisite_menu ?? []).some(program =>
                                                                            cartItem.id === program.id
                                                                            )
                                                                        )
                                                                        )
                                                                    ) ? false : true}
                                                                    checked={selectedProgramId.some(
                                                                        (selectedMenu) =>
                                                                            selectedMenu.id === item.id && selectedMenu.id_category === item.id_category
                                                                    )}
                                                                    onChange={() =>
                                                                        handleSelectBeliXDapatYCategoryMenu(item, item.id_category, item.max_qty)
                                                                    }
                                                                />
                                                            }
                                                            label={`${item.nama}`}
                                                            value={item}
                                                        />
                                                    {/* Conditional Rendering for Plus/Minus Buttons */}
                                                    {item.max_qty > 1 && selectedProgramId.some(
                                                                        (selectedMenu) =>
                                                                            selectedMenu.id === item.id && selectedMenu.id_category === item.id_category
                                                                    ) && (
                                                        <div style={{ display: "flex", alignItems: "center", marginTop: "5px", gap: "10px" }}>
                                                            <button 
                                                                style={{
                                                                    padding: "5px 10px",
                                                                    border: "none",
                                                                    backgroundColor: "#ccc",
                                                                    cursor: "pointer",
                                                                    borderRadius: "5px"
                                                                }}
                                                                onClick={() => handleCustomQty(item, -1)}
                                                            >
                                                                -
                                                            </button>

                                                            <span>{(selectedProgramId.filter((selected)=> selected.id === item.id))[0].quantity}</span>

                                                            <button 
                                                                style={{
                                                                    padding: "5px 10px",
                                                                    border: "none",
                                                                    backgroundColor: "#68BB59",
                                                                    color: "white",
                                                                    cursor: "pointer",
                                                                    borderRadius: "5px"
                                                                }}
                                                                onClick={() => handleCustomQty(item, 1)}
                                                            >
                                                                +
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            ))
                                        ) : (
                                            <Typography>Tidak ada data menu didalam kategori ini.</Typography>
                                        )}
                                    </DialogContent>

                                        {/* Dialog Actions */}
                                        <DialogActions>
                                            <Button onClick={() => {setOpenDialogMenu(false);setSelectedProgramId([])}} color="secondary">
                                                Batal
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    handleAddToCartBeliXDapatYCategory(selectedProgramId);
                                                    setOpenDialogMenu(false);setSelectedProgramId([]);handleUnsetPromo('voucher')
                                                }}
                                                // color="primary"
                                                style={{backgroundColor:"#1fb0e6", color:'white'}}
                                                variant="contained"
                                            >
                                                Tambahkan ke Keranjang
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                            </div>
                        ))
                        )}
                </div>
            ))}
            {programData.data[0].menus ? 
                <>
                    <div style={{pointerEvents: selectedProgramId?.length ===0||checkPrerequisitesMet(programData,cartData) ? 'auto':"none"}} onClick={() => {handleAddToCartBeliXDapatY();handleUnsetPromo('voucher')}}>
                        <Button style={{
                            width:"100%",
                            backgroundColor: selectedProgramId?.length === 0    
                            ? "gray" 
                            : "#1fb0e6",
                            color:"white",
                            "&:disabled": { backgroundColor: "gray", color: "white" },
                            zIndex:'-1'
                            // marginBottom : props.specialDiscount>0 ? props.selectedVoucher?.id !== undefined ? "41%" : "5%" : "0" 
                            // marginBottom:"15%"
                        }} 
                        variant="contained" color="primary" onClick={()=>{handleAddToCartBeliXDapatY();handleUnsetPromo('voucher')}} disabled={selectedProgramId?.length ===0 }>
                            <Typography>Tambahkan ke Keranjang</Typography>
                        </Button>
                    </div>
                    
                
                    <Dialog 
                    fullWidth
                    sx={{
                        "& .MuiDialog-paper": {
                            width: isMobile ? "100%" : `${(width*25)/100}`,
                            maxWidth: "480px", // Hindari batas max width bawaan MUI
                            margin: isMobile ? "0" : "auto", // Agar tetap di tengah di PC
                        }
                    }}
                    open={openDialog} onClose={() => setOpenDialog(false)}>
                        <DialogTitle>Informasi</DialogTitle>
                        <DialogContent>
                            <Typography>Program Hanya dapat digunakan sekali!</Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenDialog(false)} color="secondary">
                                Tutup
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
                    :
                <>
                    {/* Popup Dialog */}
                    <Dialog 
                    fullWidth
                    sx={{
                        "& .MuiDialog-paper": {
                            width: isMobile ? "100%" : `${(width*25)/100}`,
                            maxWidth: "480px", // Hindari batas max width bawaan MUI
                            margin: isMobile ? "0" : "auto", // Agar tetap di tengah di PC
                        }
                    }}
                    open={openDialog} onClose={() => setOpenDialog(false)}>
                        <DialogTitle>Informasi</DialogTitle>
                        <DialogContent>
                            <Typography>Anda sudah memilih item gratis, hapus terlebih dahulu item yang anda pilih bila ingin menggantinya</Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenDialog(false)} color="secondary">
                                Tutup
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
                
            }
        </div>
    ):(
        <div></div>
    );
}