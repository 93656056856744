import { Box, Button, Dialog, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DefaultContainer from "../components/DefaultContainer";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { decreaseQuantity, deleteAllFromCart, increaseQuantity, addToCart, deleteFromCart } from "../config/redux/actions/cartActions";
import { connect } from "react-redux";
import CartItemCard from "../components/CartItemCard";
import { calculate, create, getProgramActive } from "../services/Transactions";
import MoneyFormat from "../components/MoneyFormat";
import DefaultButton from "../components/DefaultButton";
import SkeletonCalculate from "../components/SkeletonCalculate";
import DefaultHeader from "../components/DefaultHeader";
import ls from "local-storage";
import Toast from "../components/Snackbar";
import Confirmation from "../components/Confirmation";
import Warning from "../components/Warning";
import SmartWaiter from "../components/SmartWaiter";
import { getPrograms } from "../services/Programs";
import Program from "../components/Program"
import { Modal } from "@material-ui/core";
import { useRef } from "react";
import { flushSync } from "react-dom";
import Overlay from "../components/Overlay";
import { getSpecialDiscount } from "../services/Partners";
import { deleteRedeem, getRedeemVoucher, getVoucher, getVoucherMembership, redeem } from "../services/Vouchers";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber"; // Ticket icon
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { isNumber } from "lodash";
import { useMediaQuery, useTheme } from "@mui/material";
import { useDispatch } from "react-redux";
import { getByID } from "../services/Products";
import axios from "axios";

const CartScreen = ({ deleteAllFromCart, cartData, userDetail, decreaseQuantity, increaseQuantity,addToCart}) => {
  const dispatch = useDispatch()
  const [calculateResponse, setCalculateResponse] = useState([]);
  const [loadingCalculate, setLoadingCalculate] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [status, setStatus] = useState(0);
  const [msg, setMsg] = useState("");
  const [isOpeningHour, setIsOpeningHour] = useState(false);
  const [msgToast, setMsgToast] = useState("");
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [programData, setProgramData] = useState("");
  const [programDataToCart, setProgramDataToCart] = useState("")
  const [isTakeaway, setIsTakeaway] = useState(0);
  const [chooseTransactionType, setChooseTransactionType] = useState(false);
  const [dynamicHeight, setDynamicHeight] = useState("");
  const [dynamicRefBottom, setDynamicRefBottom] = useState("");
  const [paymentWay, setPaymentWay] = useState(0);
  const [choosePaymentWay, setChoosePaymentWay] = useState(false);
  const [isMembership, setIsMembership] = useState(true)
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // <600px
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md")); // 600px - 960px
  const [modalVoucher, setModalVoucher] = useState(false)
  const [expandedVoucherId, setExpandedVoucherId] = useState(null);
  const [voucherList, setVoucherList] =  useState(true);
  const [vouchers, setVouchers] = useState([])
  const [selectedVoucher, setSelectedVoucher] = useState([])
  const [voucherTypeModal, setVoucherTypeModal] = useState(false)
  const [redeemVoucherModal, setRedeemVoucherModal] = useState(false)
  const [programId, setProgramId] = useState(0)
  const [redeemableVoucher, setRedeemableVoucher] = useState("")
  const [idTransaction, setIdTransaction] = useState(0)
  const [specialDiscount, setSpecialDiscount] = useState(0)
  const [choosenTrxType, setChoosenTrxType] = useState(false)
  const [recalculate, setRecalculate] = useState(0)
  const [renderCounter, setRenderCounter] = useState(0)
  const [isTokenLoggedIn, setIsTokenLoggedIn] = useState(true)
  const [voucherMembership, setVoucherMembership] = useState([])
  const [voucherMemberModal, setVoucherMemberModal] = useState(false)
  const [cashierDisabled, setCashierDisabled] = useState(false)
  const [handphoneDisabled, setHandphoneDisabled] = useState(false)
  const [dineInDisabled, setDineInDisabled] = useState(false)
  const [takeAwayDisabled, setTakeAwayDisabled] = useState(false)
  const [discountItem, setDiscountItem] = useState([])
  const hasCalculatedVoucher = useRef(false);
  const hours = new Date().getHours();
  const minutes = new Date().getMinutes();
  const seconds = new Date().getSeconds();

  const dynamicHeightRef = useRef()
  const dynamicBottomRef = useRef()

  const timeNow = `${hours > 9 ? hours : "0" + hours}:${minutes > 9 ? minutes : "0" + minutes
    }:${seconds > 9 ? seconds : "0" + seconds}`;

  const navigate = useNavigate();
  const location = useLocation()  

  useEffect(() => {
    if(renderCounter === 0){
      //cek take away disabled
      if(ls.get('sfPartner').is_takeaway==="0"){
        setTakeAwayDisabled(true)
      }
      if(ls.get("dataTrx") && ls.get("requested") === 1 && (ls.get("sfPartner").is_temporary_qr === 0 || ls.get("sfPartner").is_temporary_qr === "0") && (ls.get("sfPartner").open_bill === 0 || ls.get("sfPartner").open_bill === "0")){
        createTrx(0, "", 0, ls.get("dataTrx"))
        setChoosenTrxType(true)
      } else {
        if(!hasCalculatedVoucher.current){
          calculateTrx()
        }
      }
      setRenderCounter(renderCounter + 1)
    }

    if (ls.get("sfOpenHour") && ls.get("sfOpenHour") < timeNow &&
      timeNow > ls.get("sfCloseHour")
      ) {
        setIsOpeningHour(false);
        setMsgToast(
          "Mohon maaf cafe sudah tutup, silahkan coba kembali besok"
        );
        return
    }

    if(ls.get("sfOpenHour") && ls.get("sfOpenHour") > timeNow){
      setIsOpeningHour(false);
      setMsgToast("Mohon maaf cafe belum buka");
      return
    }

    if (ls.get("sfShift") === "1") {
      if (ls.get("sfOpenHour") && ls.get("sfOpenHour") < timeNow && timeNow < ls.get("sfCloseHour")) {
        setIsOpeningHour(true);
      }
    } else {
      if (ls.get("sfOpenHour") && ls.get("sfOpenHour") < timeNow &&
      timeNow > ls.get("sfLastOrder") && timeNow < ls.get("sfCloseHour")
      ) {
        setIsOpeningHour(false);
        setMsgToast(
          "Mohon maaf waktu pemesanan anda melebihi waktu last order, silahkan coba kembali besok"
        );
      return
      } else {
        setMsgToast("Resto belum memulai shift. Mohon hubungi kasir");
        return
      }
    }

    if(choosenTrxType === false && recalculate === 0){ 
      setDynamicHeight(dynamicHeightRef.current?.clientHeight)
      setDynamicRefBottom(dynamicBottomRef.current?.clientHeight)
    }

  }, [cartData]);


  function back() {
    if(idTransaction === 0){
      navigate('/order')
    }else{
      navigate('/order-received', {state: {membership:isMembership ,id:idTransaction, to: "/order", phone:ls.get("redux_localstorage_simple").userData[0].phone, ...location.state}})
    }

    // if(isMembership === true){
    //   navigate("/order");
    // } else {
    //   navigate("/membership", {state: {to: "/order", phone:ls.get("redux_localstorage_simple").userData[0].phone}});
    // }
  }

  async function createTrx(takeaway, response, paymentWay, dataFromLs) {
    setIsBtnDisabled(true);

    let data = {
      partnerID: ls.get("partnerID"),
      paymentMethod: "11",
      tableCode: ls.get("tableID"),
      paymentWay:response?.paymentWay ?? paymentWay,
      id_voucher: selectedVoucher.type === "Voucher" ? selectedVoucher.code : "",
      id_voucher_redeemable: selectedVoucher.type === "Redeem Code" ? selectedVoucher.code : selectedVoucher.type === "Voucher" ? "" : selectedVoucher.code,
      voucher_type: selectedVoucher.type,
      is_takeaway:takeaway,
      // detail: cartData,
      detail: response?.detail ?? calculateResponse.detail,
      foodcourtID: "",
      user_address_id: "",
      rate_id: "",  
      program_id: response?.program_id ?? calculateResponse.program_id,
      selectedProgram: calculateResponse.program_id ? programData.data[0] : "",
      program_discount: response?.program_discount ?? calculateResponse.program_discount,
      program_payment_method: response?.program_payment_method ?? calculateResponse.program_payment_method,
      program_title: response?.program_title ?? calculateResponse.program_title,
      promo: selectedVoucher != [] ? calculateResponse.promo : 0,
      pax: ls.get("pax") ?? 1,
      total: response?.total ?? calculateResponse.total,
      subtotal: response?.subtotal ?? calculateResponse.subtotal,
      rounding: response?.roundingDif ?? calculateResponse.roundingDif,
      totalAfterRounding: ls.get("sfPartner").is_rounding === "1" ? response?.rounding ?? calculateResponse.rounding : response?.total ?? calculateResponse.total,
      // is_delivery: 0,
      is_queue: ls.get("sfPartner").is_queue_tracking,
      // is_insurance: isInsurance,
      notes: "",
      isMembership:isMembership,
      is_temporary_qr: ls.get("trxFromQR") ? "1" : "0",
      trx_id_qr: ls.get("trxFromQR") ?? "",
      selectedVoucher: selectedVoucher,
      // delivery_fee: deliveryFee,
      // subTotal: subTotal,
      // total_program: totalProgram,
      diskon_spesial: calculateResponse.diskon_spesial,
      // delivery_detail: deliveryDetail,
    };

    const openBill = ls.get('sfPartner').open_bill
    if ((openBill === "1"  || (ls.get('sfPartner').is_temporary_qr == 1 ||  ls.get('sfPartner').is_temporary_qr == "1") ) && (paymentWay === 0 || response?.paymentWay === 0 ) ) {
      if (ls.get('sfPartner').is_temporary_qr == 0 ||  ls.get('sfPartner').is_temporary_qr == "0"){
        ls.set("dataTrx", data)
        ls.set("requested", 1)
      }
      
      const response = await create(data)
      
      if(response.is_membership === false){
        setIsMembership(false)
      }
      if (response.success === 1) {
        
        if (ls.get('sfPartner').is_temporary_qr == 0 ||  ls.get('sfPartner').is_temporary_qr == "0"){
          ls.remove("requested")
          ls.remove("dataTrx")
        }

        deleteAllFromCart();

        
        setIsOpen(true);
        setMsg(response.msg);
        setStatus(response.success);
        setIdTransaction(response.transaction_id)
        
        // setTimeout(() => {
        navigate("/order-success", {
          replace: true,
          state: { from: "/order" },
        });
        // }, 1000);
      } else {
        setRecalculate(prev => prev + 1)
        setChoosenTrxType(false)
        setIsOpen(true);
        setMsg(response.msg);
        setStatus(response.success);

        setTimeout(() => {
          setIsBtnDisabled(false);
          setIsOpen(false);
        }, 800);
      }
    } else {
      navigate("/payment", { state: data })
    }
  }

  async function fetchSpecialDiscount(){
    const response = await getSpecialDiscount(ls.get('sfPartner').id)
    setSpecialDiscount(response.max_discount)
    return response.max_discount
  }

  async function calculateTrxVoucher(voucherDetail){
    hasCalculatedVoucher.current = true;
    setLoadingCalculate(true);
    setSelectedVoucher(voucherDetail)

    let data;
    // const res = await getProgramActive(ls.get('sfPartner').id)

    // if (res.success === 1) {
    //   data = res.data;
    //   setProgramData(data)
    // }
    console.log(voucherDetail);
    
    let calculateData = {
      program_id : programId,
      is_program_discount: data && data.master_program_id === "2" ? "1" : "0",
      program_discount_id: data && data.master_program_id === "2" ? data.id : "",
      program_discount_name: data && data.master_program_id === "2" ? data.title : "",
      masterID: voucherDetail.type === "Redeem Code" ? ls.get("sfPartner").id_master : "1",
      partnerID: ls.get("partnerID"),
      id_voucher: voucherDetail.type === "Voucher" ? voucherDetail.code : "",
      id_voucher_redeemable: voucherDetail.type === "Redeem Code" ? voucherDetail.code : voucherDetail.type === "Voucher" ? "" : voucherDetail.code,
      voucher_type: voucherDetail.type,
      diskon_spesial: 0,
      transaction_type: 0,
      is_delivery: "0",
      delivery_detail: "",
      detail: cartData,
      is_rounding: ls.get("sfPartner").is_rounding,
      rounding_digits: ls.get("sfPartner").rounding_digits,
      rounding_down_below: ls.get("sfPartner").rounding_down_below
    };

    await calculate(calculateData).then((response) => {
      setCalculateResponse(response);
      fetchVouchers(response)

      if(response.msg === "success"){
        let checkProgramArray = cartData.filter((item) => item.status) 
        // if(checkProgramArray.length===0){
        //   setStatus(1)
        //   setMsg(response.msg);
        //   setTimeout(() => {
        //     setIsOpen(true);
        
        //     // Hide after 3 seconds (adjust timing as needed)
        //     setTimeout(() => {
        //       setIsOpen(false);
        //     }, 3000);
        
        //   }, 300);
        // }else{
        //   setStatus(1)
        //   setMsg("Berhasil mengganti promo");
        //   setTimeout(() => {
        //     setIsOpen(true);
        
        //     // Hide after 3 seconds (adjust timing as needed)
        //     setTimeout(() => {
        //       setIsOpen(false);
        //     }, 3000);
        
        //   }, 300);
        // }
        
        console.log(voucherDetail);
        
        // setSelectedVoucher([])
        setLoadingCalculate(false);
      } else if(location.state?.isTakeaway === 0 || location.state?.isTakeaway === 1){
        setLoadingCalculate(false);
        if(response) createTrx(location.state.isTakeaway, response, location.state.paymentWay)
      } else {
        setLoadingCalculate(false);
      }
    });

    // Simulate an async process
    setTimeout(() => {
      console.log("calculateTrxVoucher finished!");
      hasCalculatedVoucher.current = false; // Allow calculateTrx to be called again if needed
    }, 1000);
  }

  async function removeVoucher() {
    setSelectedVoucher([])
    if(selectedVoucher.type === "Redeem Code"){
      const remove = await deleteRedeem(selectedVoucher)
      calculateTrx()
    } else {
      calculateTrx()
    }
  }

  async function calculateTrx() {
    setLoadingCalculate(true);
    let data;
    const specialDisc= await fetchSpecialDiscount()
    const res = await getPrograms(ls.get('sfPartner').id)

    if (res.success === 1) {
      data = res
      data.data.filter((item)=>{
        return(item.enabled === "1")
      })
      setProgramData(data)
    }

    if(data?.data[0]?.master_program_id==="2"){
      setProgramId(data.data[0].id)
    }
    let programCart = cartData.filter((item)=> item.status)
    //cek dulu jika program diskon apakah memenuhi syarat atau tidak
    let parse ;
    if(data?.data[0].discount_type ==="3"){
      parse = data?.data[0].prerequisite_category;
    }else{
      parse = data?.data[0].prerequisite_menu;
    }
    // parse = parse.toString().split(',');
    let array_prerequisite_program = [];
    for (let i = 0; i < parse?.length; i++) {
      const id = parse[i].id
      array_prerequisite_program.push(id)
    }
      let minMaxValidator = false;
      let subtotal = 0;
      let array_filtered_cart = []
      if(data?.data[0].discount_type === "3"){
        array_filtered_cart = cartData.filter(cartItem => array_prerequisite_program.includes(cartItem.data.id_category))
      }else{
        array_filtered_cart = cartData.filter(cartItem => array_prerequisite_program.includes(cartItem.id))
      }
      
      for (let i = 0; i < array_filtered_cart.length; i++) {
        subtotal = subtotal + array_filtered_cart[i].totalPrice;
      }
      if(subtotal>=data?.data[0].minimum_value){
        minMaxValidator=true;
      }



      let validator = false;

      if (data?.data[0].discount_type === "2") {
          validator = cartData.some((cartItem) => (array_prerequisite_program.includes(cartItem.id)) && cartItem.status===undefined);
          console.log("cek menu");
          
      } else if (data?.data[0].discount_type === "3") {
        console.log("cek category");

          validator = cartData.some((cartItem) => (array_prerequisite_program.includes(cartItem.data.id_category)));
      } else {
          validator = true; 
      }

      console.log(minMaxValidator);
      console.log(array_filtered_cart)
    let calculateData = {
      program_id : data ? (programCart.length>0  ? programCart[0].is_program : data.data[0].master_program_id ==="2" ? data.data[0].id:0):0,
      is_program_discount: data && data.data[0].master_program_id === "2" ? "1" : "0",
      program_discount_id: data && data.data[0].master_program_id === "2" ?  data.data[0].id : "",
      program_discount_name: data && data.data[0].master_program_id === "2" ?  data.data[0].id : "",
      masterID: "1",
      partnerID: ls.get("partnerID"),
      id_voucher: "",
      id_voucher_redeemable: "",
      voucher_type: "",
      // id_voucher: selectedVoucher.id!==undefined && (selectedVoucher.type === "Voucher" ? selectedVoucher.code : ""),
      // id_voucher_redeemable: selectedVoucher.id!==undefined &&(selectedVoucher.type === "Redeem Code" ? selectedVoucher.code : selectedVoucher.type === "Voucher" ? "" : selectedVoucher.code),
      // voucher_type: selectedVoucher.id!==undefined && (selectedVoucher.type),
      diskon_spesial: data && data.data[0].master_program_id ==="2" && validator && minMaxValidator  ? 0:specialDisc,
      transaction_type: 0,
      is_delivery: "0",
      delivery_detail: "",
      detail: cartData,
      is_rounding: ls.get("sfPartner").is_rounding,
      rounding_digits: ls.get("sfPartner").rounding_digits,
      rounding_down_below: ls.get("sfPartner").rounding_down_below
    };

    await calculate(calculateData).then((response) => {
      setCalculateResponse(response);
      fetchVouchers(response)

      if(location.state?.isTakeaway === 0 || location.state?.isTakeaway === 1){
        setLoadingCalculate(false);
        if(response) createTrx(location.state.isTakeaway, response, location.state.paymentWay)
      } else {
        setLoadingCalculate(false);
      }

    });

  }

  useEffect(() => {
    // if(!hasCalculatedVoucher.current){
    //   calculateTrx()
    // }

    if((userDetail !== null && (!(ls.get("sfToken") === null) 
      || !ls.get("sfToken")?.includes("dC9lNEx5UHNmb2VIWURoMzQrZDNvQmRGMVVpK2FZSkpaVDlMNUljYjl6b242SWtZK1RVd29ST1J1aUdEZEl3VjA2VFFLdDMzWkc4dmtvcjVJYURz")
      || !ls.get("sfToken")?.includes("V2tDRTRkTXRJajhMTllJajdOY3pucWlKQXBLV1l2RGR1UWtwL3IyZVd5d2FQSXR6a0NCZTl3dE1FN1p0ZDlZK2tuSlJ1Y0RsWHI1Q3VsNHFCdWZqTFZzVzN3ZWZHR2lNUUt3K05ka1hSQjU1a1FVTDArMW14MWFkS2pweDRJa2ExR001SzFZcFJoTjB6T1ZSR3M3ZEtBPT0=")
    )) === false){
      setIsTokenLoggedIn(false)
    }


  //   if (ls.get("sfOpenHour") && ls.get("sfOpenHour") < timeNow &&
  //     timeNow > ls.get("sfCloseHour")
  //     ) {
  //       setIsOpeningHour(false);
  //       setMsgToast(
  //         "Mohon maaf cafe sudah tutup, silahkan coba kembali besok"
  //       );
  //       return
  //   }

  //   if(ls.get("sfOpenHour") && ls.get("sfOpenHour") > timeNow){
  //     setIsOpeningHour(false);
  //     setMsgToast("Mohon maaf cafe belum buka");
  //     return
  //   }

  //   if (ls.get("sfShift") === "1") {
  //     if (ls.get("sfOpenHour") && ls.get("sfOpenHour") < timeNow && timeNow < ls.get("sfCloseHour")) {
  //       setIsOpeningHour(true);
  //     }
  //   } else {
  //     if (ls.get("sfOpenHour") && ls.get("sfOpenHour") < timeNow &&
  //     timeNow > ls.get("sfLastOrder") && timeNow < ls.get("sfCloseHour")
  //     ) {
  //       setIsOpeningHour(false);
  //       setMsgToast(
  //         "Mohon maaf waktu pemesanan anda melebihi waktu last order, silahkan coba kembali besok"
  //       );
  //     return
  //     } else {
  //       setMsgToast("Resto belum memulai shift. Mohon hubungi kasir");
  //       return
  //     }
  //   }

  //   if(choosenTrxType === false && recalculate === 0){ 
  //     setDynamicHeight(dynamicHeightRef.current.clientHeight)
  //     setDynamicRefBottom(dynamicBottomRef.current.clientHeight)
  //   }

  }, [cartData, isTakeaway]);

  async function redeemVoucher(code) {
    if (code !== "") {
        const data = await getRedeemVoucher(code);
        
        if (data.success === 1 || data.success === "1") {
            console.log(data);
            
            //refetch the calculate function first
            await calculateTrx();
            // Refetch the vouchers instead of setting the selected voucher
            // const updatedVouchers = await fetchVouchers(calculateResponse); // Assuming fetchVouchers is the function to get updated vouchers
            // setVouchers(updatedVouchers.vouchers); 

            setStatus(data.success);
            setMsg(data.msg);

            // Reset isOpen agar toast bisa muncul kembali
            setIsOpen(false);
            setTimeout(() => {
                setIsOpen(true);
            }, 10);

            setRedeemVoucherModal(false);
        } else {
            setStatus(data.success);
            setMsg(data.msg);

            // Reset isOpen agar toast bisa muncul kembali
            setIsOpen(false);
            setTimeout(() => {
                setIsOpen(true);
            }, 10);
        }
    }
}



  let items =
    cartData &&
    cartData.map((x, i) => {
      return <CartItemCard productDetails={cartData[i]} index={i} key={i} decreaseQuantity={decreaseQuantity} increaseQuantity={increaseQuantity} deleteFromCart={deleteFromCart} />;
    });
    
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOk = () => {
    setMsg("Berhasil Hapus");
    setIsOpen(true);
    setStatus(1);

    deleteAllFromCart();

    setTimeout(() => {
      back();
    }, 1000);

    setOpen(false);
  };

  function createButton(payment){

    if(userDetail !== null && (!(ls.get("sfToken") === null)
    || !ls.get("sfToken")?.includes("dC9lNEx5UHNmb2VIWURoMzQrZDNvQmRGMVVpK2FZSkpaVDlMNUljYjl6b242SWtZK1RVd29ST1J1aUdEZEl3VjA2VFFLdDMzWkc4dmtvcjVJYURz")
    || !ls.get("sfToken")?.includes("V2tDRTRkTXRJajhMTllJajdOY3pucWlKQXBLV1l2RGR1UWtwL3IyZVd5d2FQSXR6a0NCZTl3dE1FN1p0ZDlZK2tuSlJ1Y0RsWHI1Q3VsNHFCdWZqTFZzVzN3ZWZHR2lNUUt3K05ka1hSQjU1a1FVTDArMW14MWFkS2pweDRJa2ExR001SzFZcFJoTjB6T1ZSR3M3ZEtBPT0=")
    ) && ls.get("redux_localstorage_simple")?.userData.length > 0){
        createTrx(isTakeaway, "", payment)
    } else {
      navigate("/auth", {
        replace: true,
        state: { to: "/cart", isTakeaway: isTakeaway, paymentWay:payment },
      });
    }
  }

  function createButtonTakeaway(payment, takeaway){

    if(userDetail !== null && (!(ls.get("sfToken") === null) 
    || !ls.get("sfToken")?.includes("dC9lNEx5UHNmb2VIWURoMzQrZDNvQmRGMVVpK2FZSkpaVDlMNUljYjl6b242SWtZK1RVd29ST1J1aUdEZEl3VjA2VFFLdDMzWkc4dmtvcjVJYURz")
    || !ls.get("sfToken")?.includes("V2tDRTRkTXRJajhMTllJajdOY3pucWlKQXBLV1l2RGR1UWtwL3IyZVd5d2FQSXR6a0NCZTl3dE1FN1p0ZDlZK2tuSlJ1Y0RsWHI1Q3VsNHFCdWZqTFZzVzN3ZWZHR2lNUUt3K05ka1hSQjU1a1FVTDArMW14MWFkS2pweDRJa2ExR001SzFZcFJoTjB6T1ZSR3M3ZEtBPT0=")
    ) && ls.get("redux_localstorage_simple")?.userData.length > 0){
        createTrx(takeaway, "", payment)
    } else {
      navigate("/auth", {
        replace: true,
        state: { to: "/cart", isTakeaway: takeaway, paymentWay:payment },
      });
    }
  }

  const data = (window.innerHeight > window.innerWidth) ? 100 : 125


  function handleVoucherOpen(){
    setModalVoucher(!modalVoucher)
  }

  function handleSelectVoucher(item){
    handleUnsetPromo("program")
    setSelectedVoucher(item)
    calculateTrxVoucher(item)
    setModalVoucher(false)
    setVoucherMemberModal(false)
    if(JSON.parse(item.prerequisite).payment_method === 1 || JSON.parse(item.prerequisite).payment_method === 2 || JSON.parse(item.prerequisite).payment_method === 3 || JSON.parse(item.prerequisite).payment_method === 4 || JSON.parse(item.prerequisite).payment_method === 10 || JSON.parse(item.prerequisite).payment_method === 11 ){
      setCashierDisabled(true)
    } else if (JSON.parse(item.prerequisite).payment_method && JSON.parse(item.prerequisite).payment_method !== 0){
      setHandphoneDisabled(true)
    }
    
    if(JSON.parse(item.prerequisite).transaction && (JSON.parse(item.prerequisite).transaction === 1 || JSON.parse(item.prerequisite).transaction === '1')){
      setHandphoneDisabled(true)
    }
     if (JSON.parse(item.prerequisite).transaction && (JSON.parse(item.prerequisite).transaction === 2 || JSON.parse(item.prerequisite).transaction === '2')){
      setDineInDisabled(true)
    } if (JSON.parse(item.prerequisite).transaction && (JSON.parse(item.prerequisite).transaction === 1 || JSON.parse(item.prerequisite).transaction === '1')){
      setTakeAwayDisabled(true)
    }
  }

  async function handleInput(keyword) {
    setRedeemableVoucher(keyword)
    setIsOpen(false)
  }

  async function fetchVouchers(res) {
    const response = await getVoucher();
    console.log(response);
    
    // Filter vouchers that belong to the partner or master
    const partnerVoucher = response.vouchers
        .filter((item) => 
            item.partner_id === ls.get("sfPartner").id || 
            item.master_id === ls.get("sfPartner").id_master
        )
        .map((item) => {
            const parse = JSON.parse(item.prerequisite);
            let minMaxValidator = false;
            if(item.is_percent==="2"){
              minMaxValidator= true;
            } else {
              minMaxValidator = parse.min <= res.subtotal;
            }
            let validator = false;
            let prerequisiteMenu = parse.menu_id?.toString().split(',');
            let prerequisiteCategory = parse.category_id?.toString().split(',');

            if (item.type_id === "2") {
                validator = cartData.some((cartItem) => (prerequisiteMenu.includes(cartItem.id)) && cartItem.status===undefined);
                if(item.is_percent==="2"){
                  validator = !cartData.some((cartItem) => !(prerequisiteMenu.includes(cartItem.id)))
                }
            } else if (item.type_id === "3") {
                validator = cartData.some((cartItem) => (prerequisiteCategory.includes(cartItem.data.id_category)));
                if(item.is_percent==="2"){
                  validator = !cartData.some((cartItem) => !(prerequisiteCategory.includes(cartItem.data.id_category)));
                }
            } else {
                validator = true; // For type_id "1", assume it's valid if minMaxValidator passes
            }
            
            return {
                ...item,
                minMaxValidator,
                validator,
                isValid: validator && minMaxValidator, // Determine if the voucher is fully valid
            };
        });
    
    const voucherMember = await getVoucherMembership();
        console.log(cartData);
        

    const dataVM = voucherMember.redeemables
        .filter((item) =>
            item.partner_id === ls.get("sfPartner").id || 
            item.master_id === ls.get("sfPartner").id_master
        )
        .map((item) => {
            const parse = JSON.parse(item.prerequisite);
            const obtained = voucherMember.usageCount.some((data) => {
                return data.voucherid === item.code && parseInt(data.useCount) < parseInt(item.total_usage);
            });

            let minMaxValidator = parse.min <= res.subtotal;
            let validator = false;

            if (item.type_id === "2") {
                validator = cartData.some((cartItem) => cartItem.id === parse.menu_id.toString());
            } else if (item.type_id === "3") {
                validator = cartData.some((cartItem) => cartItem.data.id_category === parse.category_id);
            } else {
                validator = true;
            }

            return {
                ...item,
                minMaxValidator,
                validator,
                isValid: validator && minMaxValidator && obtained,
            };
        });

    setVoucherMembership(dataVM);
    setVouchers(partnerVoucher); // Set filtered and processed vouchers

    return response;
}

  const handleDeleteAllProgramItemsXY = () => {
    let cartDataXY = cartData.filter(item => item.status); // Correctly filter the array
    console.log(cartDataXY);
    
    for (const data of cartDataXY) {
        dispatch(deleteFromCart(data)); // Call the delete function for each item
    }
};

const handleUnsetPromo = (object) => {
  let lastProgramId = programId

  let cartDataProgram = cartData.filter(item => item.status);
  if(object=="program"){
    for(const data of cartDataProgram){
      dispatch(deleteFromCart(data))
    }
    setProgramId("")
  }

  setStatus(1)
    if(cartDataProgram.length>0 || (lastProgramId!==0 || lastProgramId!=="")){
        setMsg("Berhasil mengubah promo");
    }else{
        setMsg("success")
    }
    setTimeout(() => {
        setIsOpen(true);
    
        // Hide after 3 seconds (adjust timing as needed)
        setTimeout(() => {
          setIsOpen(false);
        }, 3000);
    
      }, 300);
}

//check the smartwaiter request for conditional rendering
const api = axios.create(); // Use your existing axios instance

let isFetchingSmartWaiter = false;
let smartWaiterData       = null;

api.interceptors.request.use((config) => {
    if (config.url.includes("v8/smart_waiter/getDataSmartWaiter.php")) {
        isFetchingSmartWaiter = true;
    }
    return config;
});

api.interceptors.response.use(
    (response) => {
        if (response.config.url.includes("v8/smart_waiter/getDataSmartWaiter.php")) {
            isFetchingSmartWaiter = false;
            smartWaiterData = response.data; // Store the data
        }
        return response;
    },
    (error) => {
        if (error.config.url.includes("v8/smart_waiter/getDataSmartWaiter.php")) {
            isFetchingSmartWaiter = false;
            smartWaiterData = null; // Reset on error
        }
        return Promise.reject(error);
    }
);
const isSmartWaiterFetching = () => isFetchingSmartWaiter;
const getSmartWaiterData = () => smartWaiterData;

// Handle click outside
const modalRef = useRef(null);
 useEffect(() => {
  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setModalVoucher(false); // Close modal
    }
  };

  document.addEventListener("mousedown", handleClickOutside);
  
  return () => {
    document.removeEventListener("mousedown", handleClickOutside);
  };
}, []);

const getProgramForCart = async () => {
  const res = await getPrograms(ls.get('sfPartner').id)
  setProgramDataToCart(res)
}
useEffect(()=>{
  getProgramForCart()
},[calculateResponse])

return (
  <>

        {choosenTrxType && (ls.get('sfPartner').open_bill === "1" || ls.get('sfPartner').is_temporary_qr === "1" || ls.get('sfPartner').is_temporary_qr === 1)  ?
          <Overlay message="Transaksi Sedang Diproses" loading={true}/> :
          <DefaultContainer loading={loadingCalculate}>
            <Toast isOpen={isOpen} message={msg} status={status} />



        <Dialog
          open={chooseTransactionType}
          PaperProps={{
            style: {borderRadius:"12px"}
          }}
        >
          <Box
            style={{
              height:"auto",
              width:"240px",
              backgroundColor:"white",
              zIndex:9999,
              display:"flex",
              flexWrap: "wrap",
              alignContent:"flex-start"
            }} 
          >
            <div 
              style={{
                display:"flex",
                width:"100%",
                height:"10%",
                justifyContent:"flex-end"
              }}
            >
              <Button
                onClick={()=>{
                  setChooseTransactionType(false)
                  }
                }
                style={{
                  paddingTop:"2.5vh"
                }}
              >
                X
              </Button>
            </div>
            <div 
              style={{
                display:"flex",
                width:"100%",
                height:"10%",
                justifyContent:"center",
                alignItems:"baseline",
                paddingTop:"1vh",
                paddingBottom:"1.5vh",
              }}
            >
              <Typography>Pilih Mana?</Typography>
            </div>
            <Button
              style={{
                display:"flex",
                width:"100%",
                flexWrap:"wrap",
                paddingTop:"2vh",
                paddingBottom:"2vh",
                paddingRight:window.innerHeight > window.innerWidth ? "7vw" : "2vw",
                paddingLeft:window.innerHeight > window.innerWidth ? "4vw" : "1.5vw",
                justifyContent:"space-between"
              }}
              disabled={
                dineInDisabled || 
                (calculateResponse?.program_discount > 0 && 
                programData?.data[0].transaction_type?.some((item) => item.label === "Dine In") 
                  ? false 
                  : (programData.length === 0 || programData === "") || 
                    calculateResponse?.program_discount === 0 
                    ? false 
                    : true)
              }
              onClick={()=>{
                setChooseTransactionType(false)
                setIsTakeaway(0)
                if(ls.get('sfPartner').open_bill === "1"){
                  setChoosePaymentWay(false)
                  createButton(0)
                } else {
                  setChoosePaymentWay(true)
                }                
              }}
              >
                <div
                  style={{
                    width:"40%",
                    textAlign:"left"
                  }}
                >
                  <img
                    src={"/assets/img/menu.png"}
                    alt="dine-in"
                    width={70} 
                  />
                </div>
                <div style={{width:"60%", textAlign:"right"}}>
                Dine In
                {
                  calculateResponse?.program_discount > 0 && programData?.data[0].transaction_type?.some((item) => item.label === 'Dine In') ? 
                  <></> :
                  (programData == [] || programData === "") || calculateResponse?.program_discount === 0 ?
                  <></> :
                  <Typography style={{color:"red", fontSize:"0.6rem", textTransform:"none"}}>
                    Tipe transaksi tidak dapat digunakan untuk jenis promo/diskon yang didapat
                  </Typography>
                }
              </div>
              </Button>
              <Button
              style={{
                display:"flex",
                width:"100%",
                flexWrap:"wrap",
                paddingBottom:"3vh",
                paddingRight:window.innerHeight > window.innerWidth ? "7vw" : "2vw",
                paddingLeft:window.innerHeight > window.innerWidth ? "7.5vw" : "2.5vw",
                justifyContent:"space-between"
              }}
              disabled={
                takeAwayDisabled || 
                (calculateResponse?.program_discount > 0 && 
                programData?.data[0].transaction_type?.some((item) => item.label === "Take Away") 
                  ? false 
                  : (programData.length === 0 || programData === "") || 
                    calculateResponse?.program_discount === 0 
                    ? false 
                    : true)
              }
              onClick={()=>{
                  setChooseTransactionType(false)
                  setIsTakeaway(1)
                  // if(ls.get('sfPartner').open_bill === "1"){
                    setChoosePaymentWay(false)
                    createButtonTakeaway(1,1)
                  // } else {
                  //   setChoosePaymentWay(true)
                  // }
                }
              }
            >
              <div
                style={{
                  width:"40%",
                  marginLeft:"-5px"
                }}
              >
                <img
                  src={"/assets/img/take-away.png"}
                  alt="take-away"
                  width={70}
                />
              </div>
              <div style={{width:"60%", textAlign:"right"}}>
                Take Away
                {
                  calculateResponse?.program_discount > 0 && programData?.data[0].transaction_type?.some((item) => item.label === 'Take Away') ? 
                  <></> :
                  (programData == [] || programData === "") || calculateResponse?.program_discount === 0  ?
                  <></> :
                  <Typography style={{color:"red", fontSize:"0.6rem", textTransform:"none"}}>
                    Tipe transaksi tidak dapat digunakan untuk jenis promo/diskon yang didapat
                  </Typography>
                }
              </div>
            </Button>
          </Box>
        </Dialog>

              <Dialog
                open={choosePaymentWay}
                PaperProps={{
                  style: {borderRadius:"12px"}
                }}
              >
                <Box
                  style={{
                    height:"auto",
                    width:"265px",
                    backgroundColor:"white",
                    zIndex:9999,
                    display:"flex",
                    flexWrap: "wrap",
                    alignContent:"flex-start",
                  }} 
                >
                  <div 
                    style={{
                      display:"flex",
                      width:"100%",
                      height:"10%",
                      justifyContent:"flex-end"
                    }}
                  >
                    <Button
                      onClick={()=>{
                        setChoosePaymentWay(false)
                        }
                      }
                      style={{
                        paddingTop:"2.5vh"
                      }}
                    >
                      X
                    </Button>
                  </div>
                  <div 
                    style={{
                      display:"flex",
                      width:"100%",
                      height:"10%",
                      justifyContent:"center",
                      alignItems:"baseline",
                      paddingTop:"1vh",
                      paddingBottom:"1.5vh",
                    }}
                  >
                    <Typography>Mau Bayar Di Mana?</Typography>
                  </div>
                  <Button
                    style={{
                      display:"flex",
                      width:"100%",
                      flexWrap:"wrap",
                      paddingTop:"2vh",
                      paddingBottom:"2vh",
                      paddingRight:window.innerHeight > window.innerWidth ? "7vw" : "2vw",
                      paddingLeft:window.innerHeight > window.innerWidth ? "4vw" : "1.5vw",
                      marginBottom:"2vh",
                      justifyContent:"space-between"
                    }}
              disabled = {cashierDisabled}
                    onClick={()=>{
                      setChooseTransactionType(false)
                      setPaymentWay(0)
                      createButton(0)
                    }}
                    >
                      <div
                        style={{
                          width:"40%",
                          textAlign:"left"
                        }}
                      >
                        <img
                          src={"/assets/img/cashier.png"}
                          alt="dine-in"
                          width={70} 
                        />
                      </div>
                      Bayar Di Kasir
                  </Button>
                  <Button
                    style={{
                      display:"flex",
                      width:"100%",
                      flexWrap:"wrap",
                      paddingBottom:"3vh",
                      paddingRight:window.innerHeight > window.innerWidth ? "7vw" : "2vw",
                      paddingLeft:window.innerHeight > window.innerWidth ? "7.5vw" : "2.5vw",
                      justifyContent:"space-between"
                    }}
                    onClick={()=>{
                        setChooseTransactionType(false)
                        setPaymentWay(1)
                        createButton(1)
                      }
                    }
              disabled = {handphoneDisabled}
                  >
                    <div
                      style={{
                        width:"40%",
                        marginLeft:"-15px"
                      }}
                    >
                      <img
                        src={"/assets/img/handphone.png"}
                        alt="take-away"
                        width={70}
                      />
                    </div>
                    <div style={{width:"60%", textAlign:"right"}}>
                      Bayar Di Handphone
                    </div>
                  </Button>
                </Box>
              </Dialog>

            <Confirmation
              open={open}
              onOk={handleClickOk}        
              onClose={handleClose}
              title={"Hapus Semua Item"}
              confirmMsg={"Anda yakin akan ingin menghapus semua item pada cart? Semua item yang terhapus tidak dapat dikembalikan"}
            />
            <Box>
              {/* <Grid style={{ margin: 15 }}> */}
              <Grid>
                <Grid container>
                  {isOpeningHour === false &&
                    <Warning
                      msg={msgToast}
                    />
                  }
                  <Grid
                    item
                    xs={12}
                    style={{
                      background: "#fff",
                      padding: 15,
                      textAlign: "center",
                      position: "sticky",
                      top: 0,
                    }}
                  >
                    <DefaultHeader
                      title={"Cart"}
                      trailing={
                        <DeleteOutlineIcon
                          onClick={() => {
                            handleClickOpen()
                          }}
                          />
                        }
                      url={"/order"}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      padding: "0px 15px",
                      marginTop: 15,
                      marginBottom: dynamicRefBottom + data,
                    }}
                  >
                    <div ref={dynamicHeightRef}>
                    {cartData && cartData.length > 0 ? (
                      <>
                          {/* Render regular items */}
                          {cartData
                              .filter(item => item.status === undefined)
                              .map((item, i) => (
                                  <CartItemCard 
                                      key={`Regular_${i}`} 
                                      productDetails={item} 
                                      index={i} 
                                      decreaseQuantity={decreaseQuantity} 
                                      increaseQuantity={increaseQuantity} 
                                      deleteFromCart={deleteFromCart} 
                                      programData={programDataToCart}
                                      isUsingProgram={(selectedVoucher.id===undefined)}
                                      isUsingVoucher = {(selectedVoucher.id!==undefined)}
                                      voucherData = {selectedVoucher}
                                  />
                              ))
                          }

                          {/* Render Produk Program header only if there are Beli X Dapat Y items */}
                          {cartData.some(item => item.status) && (
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "1rem" }}>
                                <Typography variant="p" sx={{ fontWeight: "bold" }}>
                                    Item Gratis yang Anda Pilih
                                </Typography>
                                <Button
                                  onClick={handleDeleteAllProgramItemsXY}
                                >
                                  <DeleteOutlineIcon
                                      hidden={!cartData.some(item => item.status)}
                                      variant="contained" 
                                      color="black" 
                                      // style={{marginRight:"2rem"}}
                                      sx={{ textTransform: "none" }}
                                      onClick={handleDeleteAllProgramItemsXY}

                                  />
                                </Button>
                            </Box>
                        )}

                          {/* Render Beli X Dapat Y items */}
                          {cartData
                              .filter(item => item.status)
                              .map((item, i) => (
                                  <CartItemCard 
                                      key={`BeliX_DapatY_${i}`} 
                                      productDetails={item} 
                                      index={i} 
                                      decreaseQuantity={decreaseQuantity} 
                                      increaseQuantity={increaseQuantity} 
                                      deleteFromCart={deleteFromCart} 
                                  />
                              ))
                          }
                      </>
                  ) : back()}
                              { 
                                // selectedVoucher.id===undefined &&
                                <Program 
                                  calculateData={calculateResponse} 
                                  programData={programData} 
                                  addToCart={addToCart} 
                                  deleteFromCart={deleteFromCart} 
                                  specialDiscount={specialDiscount} 
                                  selectedVoucher={selectedVoucher} 
                                  setSelectedVoucher={setSelectedVoucher}
                                  setMsg={setMsg}
                                  setIsOpen={setIsOpen}
                                  setStatus={setStatus}
                                  isSmartWaiterFetching={isSmartWaiterFetching}
                                  getSmartWaiterData={getSmartWaiterData}/>
                              }
                      {
                        JSON.stringify(selectedVoucher) === JSON.stringify([]) && isTokenLoggedIn ?
                        <div
                          style={{
                            display:"flex",
                            flexWrap:"wrap",
                            alignItems:"center",
                            justifyContent:"center",
                            justifySelf:"center",
                            width:"100%",
                            textAlign:"center",
                            cursor:"pointer"
                            // backgroundColor:"red"
                          }}

                          onClick={()=>{
                            setVoucherTypeModal(true)
                          }}
                        >
                          <div
                            style={{
                              width:"100%",
                              backgroundColor:"white",
                              borderRadius:"11px",
                              color:"black",
                              marginBottom:"15px",
                              marginTop:"15px",
                              // paddingLeft:"10px",
                              // paddingTop:"10px",
                              // paddingBottom:"10px",
                              fontWeight:"bold",
                              textAlign:"left",
                              borderRadius:"10px"
                            }}
                            onClick={handleVoucherOpen}
                          >
                          {
                          // !checkProgram() &&
                            <Button
                            style={{
                              display: "flex",
                              justifyContent: "space-between", // Jarak antara teks dan ikon
                              alignItems: "center", // Agar ikon sejajar dengan teks
                              width: "100%", // Supaya elemen penuh dalam container-nya
                              color: "black",
                              textTransform: "none",
                              position: "relative",
                              marginTop:programData ? "2%":"-5%",
                              marginBottom: isSmartWaiterFetching() === true && getSmartWaiterData()===null ? "10%":"0"
                            }}
                          >
                            Gunakan Voucher
                            <div style={{ position: "relative", display: "inline-block", marginLeft: "8px", marginRight: "10px"}}>
                              <ConfirmationNumberIcon />
                          
                              {/* Badge */}
                              {vouchers.length > 0 && (
                                <span
                                  style={{
                                    position: "absolute",
                                    top: "-5px",
                                    right: "-5px",
                                    backgroundColor: "red",
                                    color: "white",
                                    borderRadius: "50%",
                                    width: "15px",
                                    height: "15px",
                                    fontSize: "10px",
                                    fontWeight: "bold",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    boxShadow: "0 0 5px rgba(0,0,0,0.2)",
                                  }}
                                >
                                  {vouchers.length}
                                </span>
                              )}
                            </div>
                          </Button>
                          }
                          </div>
                        </div>
                        :
                        <></>
                      }
                      
                      {
                        modalVoucher== true ? (
                          <Modal open={modalVoucher}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              position: "fixed",
                              top: 0,
                              left: 0,
                              width: "100vw",
                              height: "100vh",
                              backgroundColor: "rgba(f, f, f, f)",
                              overflow:"scroll"
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                width: isMobile ? "100%" : isTablet ? "50%" : "27%",
                                height: "100%",
                                backgroundColor: "#f0f0f0",
                                borderRadius: "0.5rem",
                                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                                overflow: "auto",
                                padding: "5px",
                              }}
                              ref={modalRef}
                            >
                              {/* Judul Halaman */}
                              <div style={{ display: "flex", width: "100%", margin:0, gap: "0.5rem" }}>
                                <button
                                  style={{
                                    backgroundColor: "transparent",
                                    color: "black",
                                    border: "none",
                                    cursor: "pointer",
                                    alignItems: "left",
                                    textAlign: "left",
                                    justifyContent: "left",
                                  }}
                                  onClick={handleVoucherOpen}
                                >
                                  <ArrowBackOutlinedIcon />
                                </button>
                                <p
                                  style={{
                                    fontWeight: "bold",
                                    width: "100%",
                                    alignItems: "center",
                                    textAlign: "center",
                                    justifyContent: "center",
                                    fontSize: "1.5rem",
                                  }}
                                >
                                  Gunakan Voucher
                                </p>
                              </div>
                    
                              {/* Input Voucher */}
                              <div style={{ display: "flex", alignItems: "center", marginBottom: "1rem", gap: "0.5rem" }}>
                                <input
                                  type="text"
                                  placeholder="Masukkan kode voucher"
                                  style={{
                                    flexGrow: 1,
                                    padding: "0.5rem",
                                    borderRadius: "0.5rem",
                                    border: "1px solid #ccc",
                                  }}
                                  onChange={(e) => setRedeemableVoucher(e.target.value)}
                                />
                                <button
                                  style={{
                                    backgroundColor: "#20b0e6",
                                    color: "white",
                                    borderRadius: "0.5rem",
                                    border: "none",
                                    cursor: "pointer",
                                    textTransform: "none",
                                    padding: "0.5rem 1rem",
                                  }}
                                  onClick={() => {redeemVoucher(redeemableVoucher);}}
                                >
                                  Redeem
                                </button>
                              </div>
                    
                              {/* Pilih Voucher (Button) */}
                                <Button
                                  style={{
                                    display: "flex-col",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    width: "100%",
                                    backgroundColor: "white",
                                    border: "none",
                                    padding: "0.2rem",
                                    fontSize: "1rem",
                                    cursor: "pointer",
                                    color:"black",
                                    textTransform:"none",
                                    margin:'0',
                                    borderRadius:"0"
                                  }}
                                  onClick={() => setVoucherList(!voucherList)}
                                >
                                  <p style={{width:"100%",fontWeight: "bold",float:"left", justifyContent:"left", textAlign:'left', paddingLeft:"1rem"}}>
                                    Pilih Voucher
                                  </p>
                                  <span style={{float:'right'}}>{voucherList ? "▲" : "▼"}</span> {/* Panah atas & bawah */}
                                </Button>
                              
                    
                              {/* List Voucher dengan Animasi Dropdown */}
                              <div
                                style={{
                                  maxHeight: voucherList ? "100%" : "0",
                                  overflow: "auto", scrollbarWidth:"none",
                                  transition: "max-height 0.3s ease-in-out",
                                }}
                              > 
                                <div style={{fontSize:"0.9rem",float:"left",backgroundColor: "white", borderBottomLeftRadius: "10px",borderBottomRightRadius: "10px", padding:"1rem", margin:"0", marginBottom:"0.5rem", maxWidth:"100%", display:"block"}}>
                                  <span>
                                      Kamu hanya bisa menggunakan voucher yang sesuai dengan restoran yang kamu pilih
                                  </span>   
                                </div>
                                  {vouchers.map((item, i) => (
                                    <button
                                      key={i}
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        width: "100%",
                                        textAlign: "left",
                                        marginBottom: i==vouchers.length-1 && (isMobile || isTablet) ? "6rem" : "0.5rem",
                                        padding: "1rem",
                                        backgroundColor: "white",
                                        borderRadius: "10px",
                                        gap: "10px",
                                        color: "black",
                                        border: "1px solid #ccc",
                                        cursor: "pointer",
                                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                        position: "relative",
                                      }}
                                      onClick={() => {
                                        setExpandedVoucherId(expandedVoucherId===""||expandedVoucherId===i ? null : i); // Toggle the clicked voucher
                                      }}
                                    >
                                      {/* Gambar Voucher */}
                                      <img
                                        src={item.img || "/assets/img/image-not-found.png"}
                                        alt="Voucher Image"
                                        style={{
                                          width: "80px",
                                          height: "80px",
                                          borderRadius: "10px",
                                          objectFit: "cover",
                                        }}
                                      />

                                      {/* Detail Voucher */}
                                      <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                        <p style={{ margin: "0", fontWeight: "bold", fontSize: "1rem" }}>{item.title.length > 15 ? item.title.substring(0, 15) + "..." : item.title}</p>
                                        <p style={{ margin: "0", marginTop:"5%", marginBottom:"5%", color: "#666", fontSize: "0.9rem",textAlign:"justify"}}>{item.description}</p>
                                        
                                        {/* Animated Detail Section */}
                                        <div
                                          style={{
                                            maxHeight: expandedVoucherId === i ? "300px" : "0px", // Control height
                                            overflow: "hidden",
                                            transition: "max-height 0.3s ease-in-out", // Smooth animation
                                            textAlign:"justify" 
                                          }}
                                        >
                                          <p style={{ margin: "0", color:"#666", fontSize: "0.9rem" }}>{item.is_percent==="2" ? "Harga Flat : " : "Diskon : "} {item.is_percent === "2" 
                                                                                                                  ? "Rp. " + item.flat_price 
                                                                                                                  : item.discount > 100 
                                                                                                                      ? "Rp. " + item.discount 
                                                                                                                      : item.discount + "%"}</p>
                                          {item.is_percent==="2" ? <p style={{ margin: "0", color: "#666", fontSize: "0.9rem" }}>Max item :{JSON.parse(item.prerequisite).max_item}</p> : <></>}
                                          <p style={{ margin: "0", color: "#666", fontSize: "0.9rem" }}>Berlaku di : {item.owner_name}</p>
                                          {JSON.parse(item.prerequisite).category_id ? 
                                          <p style={{ margin: "0", color: "#666", fontSize: "0.9rem" }}>Berlaku pada kategori : {item?.categories_name?.join(", ")}</p> 
                                          : 
                                          JSON.parse(item.prerequisite).menu_id ? 
                                          <p style={{ margin: "0", color: "#666", fontSize: "0.9rem" }}>Berlaku Pada Menu : {item?.menu_name?.join(", ")}</p> 
                                          : 
                                          <></>}
                                          {item.is_percent==="2" ? <></> : <p style={{ margin: "0", color: "#666", fontSize: "0.9rem" }}>Maksimal Diskon :{"Rp. " + JSON.parse(item.prerequisite).max}</p>}
                                          {item.is_percent==="2" ? <></> : <p style={{ margin: "0", color: "#666", fontSize: "0.9rem" }}>Minimal Transaksi :{"Rp. " + JSON.parse(item.prerequisite).min}</p>}
                                          <p style={{ margin: "0", color: "#666", fontSize: "0.9rem" }}>Jenis Transaksi :{JSON.parse(item.prerequisite)?.transaction===1 ? "Dine In" : JSON.parse(item.prerequisite)?.transaction===2 ? "Takeaway" : "Semua"}</p>
                                          <p style={{ margin: "0", color: "#666", fontSize: "0.9rem" }}>Metode Pembayaran :{JSON.parse(item.prerequisite)?.payment_method===1 ? "OVO" : JSON.parse(item.prerequisite)?.payment_method===2 ? "GOPAY" : 
                                            JSON.parse(item.prerequisite)?.payment_method===3 ? "DANA" : JSON.parse(item.prerequisite)?.payment_method===4 ? "LINKAJA" : JSON.parse(item.prerequisite)?.payment_method===5 ? "TUNAI" : JSON.parse(item.prerequisite)?.payment_method===6 ? "TUNAI" : 
                                            JSON.parse(item.prerequisite)?.payment_method===7 ? "CREDIT CARD" : JSON.parse(item.prerequisite)?.payment_method===8 ? "DEBIT CARD" : JSON.parse(item.prerequisite)?.payment_method===9 ? "QRIS" :
                                            JSON.parse(item.prerequisite)?.payment_method===14 ? "QRISUR" : JSON.parse(item.prerequisite)?.payment_method===10 ? "QRIS" : "Semua"}</p>
                                          <p style={{ margin: "0", color: "#666", fontSize: "0.9rem" }}>Total Voucher : {item.total_usage}</p>
                                          {item.type==="Redeem Code" ? <p style={{ margin: "0", color: "#666", fontSize: "0.9rem" }}>Voucher Untuk Kamu : {item.remaining}</p> : <></>}
                                          {item.type==="Voucher" ? <p style={{ margin: "0", color: "#666", fontSize: "0.9rem" }}>Voucher Untuk Kamu : {JSON.parse(item.prerequisite).order-item.used_voucher}</p> : <></>}
                                          {item.is_percent==="2" ? <p style={{ margin: "0", color: "#666", fontSize: "0.9rem" }}>Syarat : Dapat digunakan ketika isi di dalam keranjang hanya terdapat produk promo flat price</p>
                                          :<></>}
                                        
                                        </div>
                                        {/* Teks Biru (Klik untuk S & K) */}
                                        <p
                                          style={{
                                            margin: "0",
                                            marginBottom:"2rem",
                                            fontSize: "0.9rem",
                                            color: "#2086e6",
                                            cursor: "pointer",
                                            textDecoration: "underline",
                                            maxHeight: expandedVoucherId !== i ? "300px" : "0px"
                                          }}
                                          onClick={(e) => {
                                            e.stopPropagation(); // Agar tidak membuka modal voucher
                                            setExpandedVoucherId(expandedVoucherId === i ? null : i);
                                          }}
                                        >
                                          S & K
                                        </p>
                                        

                                        {/* Tombol "Pakai" di Kiri Bawah */}
                                        <button
                                          disabled={!item.isValid}
                                          style={{
                                            backgroundColor: item.isValid ? "#20b0e6" : "grey",
                                            color: "white",
                                            borderRadius: "0.5rem",
                                            border: "none",
                                            padding: "0.5rem 1rem",
                                            fontWeight: "bold",
                                            cursor: item.isValid ? "pointer" : "not-allowed",
                                            position: "absolute",
                                            bottom: "10px",
                                            right: "10px",
                                          }}
                                          onClick={(e) => {
                                            e.stopPropagation(); // Agar tidak membuka modal voucher
                                            handleSelectVoucher(item);
                                            // handleUnsetPromo("program");
                                          }}
                                        >
                                          Pakai
                                        </button>
                                      </div>

                                      {/* Tanggal Berlaku di Kanan Atas */}
                                      <p
                                        style={{
                                          fontSize: "0.8rem",
                                          color: "#999",
                                          position: "absolute",
                                          top: "10px",
                                          right: "10px",
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        s.d. {item.valid_until}
                                      </p>
                                    </button>
                                  ))}
                              </div>
                            </div>
                          </div>
                        </Modal>
                        ):(<></>)
                      }

                      {
                        vouchers?.length > 0 && JSON.stringify(selectedVoucher) === JSON.stringify([]) && isTokenLoggedIn === false ?
                        <div
                          style={{
                            display:"flex",
                            flexWrap:"wrap",
                            alignItems:"center",
                            justifyContent:"center",
                            justifySelf:"center",
                            width:"100%",
                            textAlign:"center",
                            cursor:"pointer"
                            // backgroundColor:"red"
                          }}

                          onClick={()=>{
                            navigate("/auth", {
                              replace: true,
                              state: { to: "/cart"},
                            });
                          }}
                        >
                          <div
                            style={{
                              width:"100%",
                              backgroundColor:"#68BB59",
                              borderRadius:"11px",
                              color:"white",
                              marginBottom:"15px",
                              marginTop:"15px",
                              paddingLeft:"10px",
                              paddingTop:"10px",
                              paddingBottom:"10px"
                            }}
                          >
                            <Typography>
                              <u>Login</u> untuk menggunakan voucher!
                            </Typography>
                          </div>
                        </div>
                        :
                        <div style={{marginBottom:"1rem"}}></div>
                      }
                              <SmartWaiter calculateData={calculateResponse} programData={programData} addToCart={addToCart} deleteFromCart={deleteFromCart} specialDiscount={specialDiscount} selectedVoucher={selectedVoucher} cartData={cartData}/>
                            </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    textAlign="left"
                    style={{
                      paddingTop: 10,
                      paddingBottom: 15,
                      paddingLeft: 15,
                      paddingRight: 15,
                      position: "fixed",
                      bottom: 0,                      width: 480,
                      backgroundColor: "#fff",
                    }}
                  >
                    {loadingCalculate ? (
                      <>
                        <SkeletonCalculate />
                      </>
                    ) : (
                      <Grid ref={dynamicBottomRef}>
                        {   (specialDiscount !== 0 && selectedVoucher.id=== undefined && !calculateResponse?.program_discount>0) &&
                            <Grid 
                                bgcolor={'#1FB0E6'}
                                display={'flex'}
                                justifyContent={'space-between'}
                                borderRadius={'0.5rem 0.5rem 0 0'}
                                padding={1}
                            >
                                <Typography color={'#fff'} fontWeight={"500"}>
                                    Diskon Spesial
                                </Typography>
                                <Typography color={'#fff'} fontWeight={"500"}>
                                    {`${specialDiscount}%`}
                                </Typography>
                            </Grid>
                        }
                        {
                          JSON.stringify(selectedVoucher) !== JSON.stringify([]) && loadingCalculate === false?
                          <div
                            style={{
                              display:"flex",
                              flexWrap:"wrap",
                              alignItems:"center",
                              justifyContent:"center",
                              justifySelf:"center",
                              width:"100%",
                              textAlign:"center",
                              cursor:"pointer"
                              // backgroundColor:"red"
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center", // Ensure vertical alignment
                                justifyContent: "space-between", // Spread content
                                width: "100%",
                                backgroundColor: "#1FB0E6",
                                borderTopLeftRadius: "5px",
                                borderTopRightRadius: "5px",
                                color: "white",
                                marginBottom: "5px",
                                marginTop: "5px",
                                padding: "10px"
                              }}
                            >
                              {/* Discount Text */}
                              <Typography style={{ textAlign: "left" }}>
                              {selectedVoucher 
                                ? selectedVoucher.is_percent === "2"
                                    ? `Voucher flat` //(Rp. ${selectedVoucher.flat_price})
                                    : selectedVoucher.discount > 100
                                        ? `Voucher (Rp. ${selectedVoucher.discount})`
                                        : `Voucher (${selectedVoucher.discount}%)`
                                : specialDiscount > 0 
                                    ? `Member (${specialDiscount}%)`
                                    : ""
                              }
                              </Typography>

                              {/* Cancel Button */}
                              <Button
                                style={{
                                  backgroundColor: "white",
                                  borderRadius: "5px",
                                  color: "#1FB0E6",
                                  marginLeft: "auto", // Pushes the button to the right
                                  fontSize:"0.8rem"
                                }}
                                onClick={() => {
                                  setSelectedVoucher([]);
                                  removeVoucher();
                                    setMsg("Berhasil mengubah promo");
                                    setTimeout(() => {
                                    setIsOpen(true);
                                
                                    // Hide after 3 seconds (adjust timing as needed)
                                    setTimeout(() => {
                                      setIsOpen(false);
                                    }, 3000);
                                
                                  }, 300);
                                }}
                              >
                                Batal
                              </Button>
                            </div>
                          </div>
                          :
                          <></>
                        }
                        <Typography fontWeight={"500"}>Detail Tagihan</Typography>
                        <Grid container style={{ marginTop: 5 }}>
                          <Grid item xs={6} textAlign="left">
                            Subtotal
                          </Grid>
                          <Grid item xs={6} textAlign="right">
                            <MoneyFormat value={calculateResponse?.subtotal ?? 0} />
                          </Grid>
                        </Grid>
                        {   (specialDiscount !== 0 && selectedVoucher.id=== undefined && !calculateResponse?.program_discount>0) &&
                            <Grid container style={{ marginTop: 5 }}>
                                <Grid color={'#1FB0E6'} item xs={6} textAlign="left">
                                    Diskon Spesial
                                </Grid>
                                <Grid color={'#1FB0E6'} item xs={6} textAlign="right">
                                    <MoneyFormat value={(calculateResponse?.diskon_spesial ?? 0) * -1} />
                                </Grid>
                            </Grid>
                        }
                        {(calculateResponse?.program_discount ?? 0) !== 0 ? (
                          <Grid container style={{ marginTop: 5 }}>
                            <Grid item xs={6} textAlign="left" color="#1FB0E6">
                              {"Diskon (" +
                                calculateResponse?.program_title +
                                ")"}
                            </Grid>
                            <Grid item xs={6} textAlign="right">
                              <span style={{
                                color: '#1FB0E6'
                              }}
                              >
                                {"- "}<MoneyFormat value={calculateResponse?.program_discount ?? 0} />
                              </span>
                            </Grid>
                          </Grid>
                        ) : (
                          <div></div>
                        )}{" "}
                  {(calculateResponse?.promo ?? 0) !== 0 && selectedVoucher != [] ? (
                    <Grid container style={{ marginTop: 5 }}>
                      <Grid item xs={6} textAlign="left" color= '#1FB0E6'>
                        {"Voucher"}
                      </Grid>
                      <Grid item xs={6} textAlign="right">
                        <span style={{
                          color: '#1FB0E6'
                        }}
                        >
                          {"- "}<MoneyFormat value={calculateResponse?.promo ?? 0} />
                        </span>
                      </Grid>
                    </Grid>
                  ) : (
                    <div></div>
                  )}{" "}
                        {(calculateResponse?.percent_service ?? "0") !== "0" ? (
                          <Grid container style={{ marginTop: 5 }}>
                            <Grid item xs={6} textAlign="left">
                              {"Service (" +
                                calculateResponse?.percent_service +
                                "%)"}
                            </Grid>
                            <Grid item xs={6} textAlign="right">
                              <MoneyFormat value={calculateResponse?.service ?? 0} />
                            </Grid>
                          </Grid>
                        ) : (
                          <div></div>
                        )}{" "}
                        {(calculateResponse?.percent_tax ?? "0") !== "0.00" ? (
                          <Grid container style={{ marginTop: 5 }}>
                            <Grid item xs={6} textAlign="left">
                              {"Tax (" + calculateResponse?.percent_tax + "%)"}
                            </Grid>
                            <Grid item xs={6} textAlign="right">
                              <MoneyFormat value={calculateResponse?.tax ?? 0} />
                            </Grid>
                          </Grid>
                        ) : (
                          <div></div>
                        )}
                        {(calculateResponse?.roundingDif ?? "0") !== "0" ? (
                          <Grid container style={{ marginTop: 5 }}>
                            <Grid item xs={6} textAlign="left">
                              {"Total Transaksi"}
                            </Grid>
                            <Grid item xs={6} textAlign="right">
                              <MoneyFormat value={calculateResponse?.total ?? 0} />
                            </Grid>
                          </Grid>
                        ) : (
                          <div></div>
                        )}{" "}
                        {(calculateResponse?.roundingDif ?? "0") !== "0" ? (
                          <Grid container style={{ marginTop: 5 }}>
                            <Grid item xs={6} textAlign="left">
                              {"Pembulatan"}
                            </Grid>
                            <Grid item xs={6} textAlign="right">
                              <MoneyFormat value={calculateResponse?.roundingDif ?? 0} />
                            </Grid>
                          </Grid>
                        ) : (
                          <div></div>
                        )}{" "}
                        <Grid container style={{ marginTop: 5 }}>
                          <Grid item xs={6} textAlign="left">
                            <Typography fontWeight={"500"}>Total</Typography>
                          </Grid>
                          <Grid item xs={6} textAlign="right">
                            <Typography fontWeight={"500"}>
                              <MoneyFormat value={parseInt(calculateResponse?.rounding) !== 0 ? calculateResponse?.rounding : calculateResponse?.total} />
                            </Typography>
                          </Grid>
                        </Grid>
                        <DefaultButton
                          title={isBtnDisabled ? "Mohon Tunggu" : "Pesan"}
                          disabled={
                            isOpeningHour
                              ?
                              isBtnDisabled
                              :
                              true
                          }
                          onClick={async () => {
                            setChooseTransactionType(true)
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </DefaultContainer>

        }

    </>
  );
};

const mapStateToProps = (state) => {  

  return {
    cartData: state.cartData.filter(data => data.partnerID === ls.get("partnerID")),
    userDetail: (ls.get("sfToken") === null 
        || ls.get("sfToken").includes("dC9lNEx5UHNmb2VIWURoMzQrZDNvQmRGMVVpK2FZSkpaVDlMNUljYjl6b242SWtZK1RVd29ST1J1aUdEZEl3VjA2VFFLdDMzWkc4dmtvcjVJYURz")
        || ls.get("sfToken").includes("V2tDRTRkTXRJajhMTllJajdOY3pucWlKQXBLV1l2RGR1UWtwL3IyZVd5d2FQSXR6a0NCZTl3dE1FN1p0ZDlZK2tuSlJ1Y0RsWHI1Q3VsNHFCdWZqTFZzVzN3ZWZHR2lNUUt3K05ka1hSQjU1a1FVTDArMW14MWFkS2pweDRJa2ExR001SzFZcFJoTjB6T1ZSR3M3ZEtBPT0=")
        ) ? null : state.userData[state.userData.length - 1],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteAllFromCart: (addToast) => {
      dispatch(deleteAllFromCart(addToast));
    },
    decreaseQuantity: (item, addToast) => {
      dispatch(decreaseQuantity(item, addToast));
    },
    increaseQuantity: (item, addToast) => {
      dispatch(increaseQuantity(item, addToast));
    },
    addToCart: (
      item,
      addToast,
      quantityCount,
      selectedProductColor,
      selectedProductSize
    ) => {
      dispatch(
        addToCart(
          item,
          addToast,
          quantityCount,
          selectedProductColor,
          selectedProductSize
        )
      );
    },
    deleteItemCart: (item, addToast) => {
      dispatch(deleteFromCart(item, addToast));
  },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartScreen);
