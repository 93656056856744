import instance from "../axios";
import ls from "local-storage";

export const getVoucher = async () => {
  const response = await instance.get(
    "vouchers/GetAll.php"
  );

  return response.data;
};

export const getVoucherMembership = async () => {
  const response = await instance.get(
    `memberships/GetRedeemablesByMasterID.php?masterID=${ls.get("sfPartner").id_master}&partnerID=${ls.get("sfPartner").id}`
  );

  return response.data;
};

export const redeem = async(code)=>{
  const data = {
    id_voucher_redeemable: code,
    redeemByCode: 1,
    masterID: ls.get("sfPartner").id_master
  } 
  const response = await instance.post(
    "memberships/Redeem.php", data
  );

  return response.data;
};

export const getRedeemVoucher = async(code)=>{
  const data = {
    id_voucher_redeemable: code,
    redeemByCode: 1,
    masterID: ls.get("sfPartner").id_master
  } 
  const response = await instance.post(
    "memberships/GetRedeemVoucher.php", data
  );

  return response.data;
};

export const deleteRedeem = async(data)=>{
  const response = await instance.post(
    "memberships/DeleteRedeem.php", data
  );

  return response.data;
}